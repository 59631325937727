// Strip url host name
function stripUrlHostname(rawUrl) {
  // If there is no url or not start with http
  if (!rawUrl || rawUrl.indexOf('http') !== 0) {
    return rawUrl;
  }

  // Use the URL api if the browser supports it
  if (URL) {
    const parsedUrl = new URL(rawUrl);
    return parsedUrl.href.replace(parsedUrl.origin, '');
  } else {
    // Use a tag if browser not supported URL
    const a = document.createElement('a');
    a.href = rawUrl;
    return rawUrl.replace(a.origin, '');
  }
}

const knowDomains = [
  'forthwithlife.com', // Live domain
  'localhost:8080', // Local
  'localhost:3000',
  'forth.app', // Local vagrant wordpress
];

// Strip api domains
function stripUrlKnownDomain(rawUrl) {
  // Check if the rawUrl contain api domains or not
  let newUrl = rawUrl;
  for (let i = 0, size = knowDomains.length; i < size; i++) {
    const d = knowDomains[i];
    // Do replace when it contain api domain
    if (newUrl.indexOf(d) !== -1) {
      // Get rid of the http or https first
      newUrl = newUrl.replace(d, '').replace(/http[s]?:\/\//, '');

      break;
    }
  }

  return newUrl;
}

 

function toggleAlerts() { 
  var element = document.getElementById("notification-dropdown");
  element.classList.toggle("animated"); 
  element.classList.toggle("fadeInDown"); 
  if (element.style.display === "none") {
    element.style.display = "block";
  } else {
    element.style.display = "none";
  }
 
}



function returnDaysToNextTest() { 
  return 180; 
}



function percentageCalc($value, $total, $round = 0) {
  var $calc = $value / $total * 100;
  if ($round == 1) {
    $calc = Math.round($calc);
  } else {
    $calc = $calc;
  }
  return ($calc);
}


function checkFileSize(e, sizeInMB) {
  //1mb = 1,048,576
  const file = e.target.files[0]
  if(file && file.size <= 1048576 * sizeInMB){
    return true;
  } else { 
    return false;
  }
}

function searchAndFilterObject(array, indicator) {
  return array.filter(function iter(o) {                
    return Object.keys(o).some(k => {
      let isInSearch = false;
      if (typeof o[k] === 'string' && o[k].toLowerCase().includes(indicator)) {
        isInSearch = true;
      }
      if (typeof o[k] === 'object' && o[k] !== null) {
        let child = o[k]; 
        Object.keys(child).some(k2 => {
          if (typeof child[k2] === 'string' && child[k2].toLowerCase().includes(indicator)) {
           isInSearch = true;
          }
        });
      }
      return isInSearch;
    });
  });
}

 
const apiUrl = process.env.NODE_ENV === 'development' ? 'http://192.168.33.10/' : 'http://api.forthwithlife.co.uk/'

function isLightColour(color) {
  const hex = color.replace('#', '');
  const c_r = parseInt(hex.substr(0, 2), 16);
  const c_g = parseInt(hex.substr(2, 2), 16);
  const c_b = parseInt(hex.substr(4, 2), 16);
  const brightness = ((c_r * 299) + (c_g * 587) + (c_b * 114)) / 1000;
  return brightness > 155;
}

function getDomain(url) {
  var TLDs = [
    "ac",
    "ad",
    "ae",
    "aero",
    "af",
    "ag",
    "ai",
    "al",
    "am",
    "an",
    "ao",
    "aq",
    "ar",
    "arpa",
    "as",
    "asia",
    "at",
    "au",
    "aw",
    "ax",
    "az",
    "ba",
    "bb",
    "bd",
    "be",
    "bf",
    "bg",
    "bh",
    "bi",
    "biz",
    "bj",
    "bm",
    "bn",
    "bo",
    "br",
    "bs",
    "bt",
    "bv",
    "bw",
    "by",
    "bz",
    "ca",
    "cat",
    "cc",
    "cd",
    "cf",
    "cg",
    "ch",
    "ci",
    "ck",
    "cl",
    "cm",
    "cn",
    "co",
    "com",
    "coop",
    "cr",
    "cu",
    "cv",
    "cx",
    "cy",
    "cz",
    "de",
    "dj",
    "dk",
    "dm",
    "do",
    "dz",
    "ec",
    "edu",
    "ee",
    "eg",
    "er",
    "es",
    "et",
    "eu",
    "fi",
    "fj",
    "fk",
    "fm",
    "fo",
    "fr",
    "ga",
    "gb",
    "gd",
    "ge",
    "gf",
    "gg",
    "gh",
    "gi",
    "gl",
    "gm",
    "gn",
    "gov",
    "gp",
    "gq",
    "gr",
    "gs",
    "gt",
    "gu",
    "gw",
    "gy",
    "hk",
    "hm",
    "hn",
    "hr",
    "ht",
    "hu",
    "id",
    "ie",
    "il",
    "im",
    "in",
    "info",
    "int",
    "io",
    "iq",
    "ir",
    "is",
    "it",
    "je",
    "jm",
    "jo",
    "jobs",
    "jp",
    "ke",
    "kg",
    "kh",
    "ki",
    "km",
    "kn",
    "kp",
    "kr",
    "kw",
    "ky",
    "kz",
    "la",
    "lb",
    "lc",
    "li",
    "lk",
    "lr",
    "ls",
    "lt",
    "lu",
    "lv",
    "ly",
    "ma",
    "mc",
    "md",
    "me",
    "mg",
    "mh",
    "mil",
    "mk",
    "ml",
    "mm",
    "mn",
    "mo",
    "mobi",
    "mp",
    "mq",
    "mr",
    "ms",
    "mt",
    "mu",
    "museum",
    "mv",
    "mw",
    "mx",
    "my",
    "mz",
    "na",
    "name",
    "nc",
    "ne",
    "net",
    "nf",
    "ng",
    "ni",
    "nl",
    "no",
    "np",
    "nr",
    "nu",
    "nz",
    "om",
    "org",
    "pa",
    "pe",
    "pf",
    "pg",
    "ph",
    "pk",
    "pl",
    "pm",
    "pn",
    "pr",
    "pro",
    "ps",
    "pt",
    "pw",
    "py",
    "qa",
    "re",
    "ro",
    "rs",
    "ru",
    "rw",
    "sa",
    "sb",
    "sc",
    "sd",
    "se",
    "sg",
    "sh",
    "si",
    "sj",
    "sk",
    "sl",
    "sm",
    "sn",
    "so",
    "sr",
    "st",
    "su",
    "sv",
    "sy",
    "sz",
    "tc",
    "td",
    "tel",
    "tf",
    "tg",
    "th",
    "tj",
    "tk",
    "tl",
    "tm",
    "tn",
    "to",
    "tp",
    "tr",
    "travel",
    "tt",
    "tv",
    "tw",
    "tz",
    "ua",
    "ug",
    "uk",
    "us",
    "uy",
    "uz",
    "va",
    "vc",
    "ve",
    "vg",
    "vi",
    "vn",
    "vu",
    "wf",
    "ws",
    "xn--0zwm56d",
    "xn--11b5bs3a9aj6g",
    "xn--3e0b707e",
    "xn--45brj9c",
    "xn--80akhbyknj4f",
    "xn--90a3ac",
    "xn--9t4b11yi5a",
    "xn--clchc0ea0b2g2a9gcd",
    "xn--deba0ad",
    "xn--fiqs8s",
    "xn--fiqz9s",
    "xn--fpcrj9c3d",
    "xn--fzc2c9e2c",
    "xn--g6w251d",
    "xn--gecrj9c",
    "xn--h2brj9c",
    "xn--hgbk6aj7f53bba",
    "xn--hlcj6aya9esc7a",
    "xn--j6w193g",
    "xn--jxalpdlp",
    "xn--kgbechtv",
    "xn--kprw13d",
    "xn--kpry57d",
    "xn--lgbbat1ad8j",
    "xn--mgbaam7a8h",
    "xn--mgbayh7gpa",
    "xn--mgbbh1a71e",
    "xn--mgbc0a9azcg",
    "xn--mgberp4a5d4ar",
    "xn--o3cw4h",
    "xn--ogbpf8fl",
    "xn--p1ai",
    "xn--pgbs0dh",
    "xn--s9brj9c",
    "xn--wgbh1c",
    "xn--wgbl6a",
    "xn--xkc2al3hye2a",
    "xn--xkc2dl3a5ee0h",
    "xn--yfro4i67o",
    "xn--ygbi2ammx",
    "xn--zckzah",
    "xxx",
    "ye",
    "yt",
    "za",
    "zm",
    "zw",
  ].join();

  var parts = url.split(".");
  if (parts[0] === "www" && parts[1] !== "com") {
    parts.shift();
  }
  var ln = parts.length,
    i = ln,
    minLength = parts[parts.length - 1].length,
    part;

  // iterate backwards
  while ((part = parts[--i])) {
    // stop when we find a non-TLD part
    if (
      i === 0 || // 'asia.com' (last remaining must be the SLD)
      i < ln - 2 || // TLDs only span 2 levels
      part.length < minLength || // 'www.cn.com' (valid TLD as second-level domain)
      TLDs.indexOf(part) < 0 // officialy not a TLD
    ) {
      return part;
    }
  }
}

const getWebsiteUrl = (websiteId = 1) => {
  const websiteList = {
    1: "https://www.forthwithlife.co.uk",
    2: "https://www.sportsbloodtests.co.uk"  
  }

  return websiteList[websiteId]
}

const getPartnerPortalId = (partner) => {
  var partnerPortalId = localStorage.getItem("partnerPortalId");

  if (partnerPortalId == "undefined" || partnerPortalId == 0 || !partnerPortalId) {
    if (Object.keys(partner).length > 0) {
      partnerPortalId = partner[0].partnerId
    } else {
      partnerPortalId = 0;
    }
  }
  return partnerPortalId;
}

export {
  returnDaysToNextTest,
  stripUrlHostname,
  stripUrlKnownDomain,
  toggleAlerts, 
  percentageCalc,
  isLightColour,
  checkFileSize,
  searchAndFilterObject,
  apiUrl,
  getDomain,
  getWebsiteUrl,
  getPartnerPortalId,
};
