import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { NavLink, Link, withRouter } from 'react-router-dom'
import moment from 'moment'


class PartnerHeaderNotification extends Component {

  constructor(props) {
    super(props); 
  }
  
  /*
    orderStatus = status of order decides colour/icon to use
    notificationType {
      1 = Customer Notification
      2 = Forth notification
      3 = Forth graphical notification 
    }
  */
  displayNotification(messageAttachment,messageBody,messageSubject,messageUrl,notificationType,orderId,orderReference,orderStatus,dateSent, userRead) {

    /* 
      0: Order not requested. 
      1: Order requested. 
      2: Order sent to Lab. 
      3: Lab has received order. 
      4: Lab has sent order. 
      5: User has sent blood. 
      6: Lab has recieved bloods. 
      7: Lab has sent results. 
      8: Results have been reviewed 
      9: User has viewed results
    */
    
    if (notificationType == 1) {
      
      let color;
      let icon; 
      switch (orderStatus) {
        case '0':
          color = 'red'; 
          icon = 'far fa-hand-paper';
          break;
        case '1':
          color = 'teal'; 
          icon = 'far fa-truck';
          break;
        case '2':
          color = 'teal'; 
          icon = 'far fa-truck';
          break;
        case '3':
          color = 'teal'; 
          icon = 'far fa-truck';
          break;
        case '4':
          color = 'teal'; 
          icon = 'far fa-truck';
          break;
        case '5':
          color = 'teal'; 
          icon = 'far fa-truck';
          break;
        case '6':
          color = 'teal'; 
          icon = 'far fa-truck';
          break;
        case '7':
          color = 'teal'; 
          icon = 'far fa-microscope';
          break;
        case '8':
          color = 'teal'; 
          icon = 'far fa-comment-medical';
          break;
        case '9':
          color = 'green'; 
          icon = 'far fa-chart-bar';
          break;
      }
       
      let readClass = '';

      if (userRead == 0) {
        readClass = ' unread';
      } 
 
      return (
        <div className={"notification-list notification-list text-start "+color+readClass}>
          <div className="row align-items-center justify-content-center">
            <div className="col-6">
              <div className="orderRef">{orderReference}</div>
            </div>
            <div className="col-6 text-end">
              <i className={icon}></i>
            </div>
            <div className="col-12 mt-2" dangerouslySetInnerHTML={{__html: messageBody}}></div>
            <div className="col-12 mt-2">
              <div className="notificationTime">{moment(dateSent).fromNow(true)}</div>
            </div>
          </div>
        </div>
      )
      
    } else if (notificationType == 2) {
      return (

        <div className={"notification-list notification-list text-start blue"+readClass}>
          <div className="row align-items-center justify-content-center">
            <div className="col-6">
              <div className="orderRef">FORTH</div>
            </div>
            <div className="col-6 text-end">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                <g id="Union_4" data-name="Union 4" fill="none">
                  <path d="M0,9A9,9,0,0,1,9,0h9V9A9,9,0,1,1,0,9Z" stroke="none"/>
                  <path d="M 8.999960899353027 2 C 5.140171051025391 2 2 5.140171051025391 2 8.999960899353027 C 2 12.85996055603027 5.140171051025391 16.00030136108398 8.999960899353027 16.00030136108398 C 12.85996055603027 16.00030136108398 16.00030136108398 12.85996055603027 16.00030136108398 8.999960899353027 L 16.00030136108398 2 L 8.999960899353027 2 M 8.999960899353027 0 L 18.00030136108398 0 L 18.00030136108398 8.999960899353027 C 18.00030136108398 13.97070121765137 13.97070121765137 18.00030136108398 8.999960899353027 18.00030136108398 C 4.029590606689453 18.00030136108398 0 13.97070121765137 0 8.999960899353027 C 0 4.029590606689453 4.029590606689453 0 8.999960899353027 0 Z" stroke="none" fill="#0793bf"/>
                </g>
              </svg>
            </div>
            <div className="col-12 mt-2">
              <Link to={messageUrl} dangerouslySetInnerHTML={{__html: messageSubject}}></Link> 
              <p dangerouslySetInnerHTML={{__html: messageBody}}></p>
            </div>
            <div className="col-12 mt-2">
              <div className="notificationTime">30 m</div>
            </div>
          </div>
        </div>

      )
    } else if (notificationType == 3) {
      return (

        <div className={"notification-list notification-list text-start white"+readClass}>
          <div className="row align-items-center justify-content-center">
            
            <div className="col-5 h-100">
              <div className="adImagePlaceholder" style={{ backgroundImage : "url({messageAttachment})" }}></div>
            </div>

            <div className="col-7">
              <div className="row adPadding">
                <div className="col-6">
                  <div className="orderRef">FORTH</div>
                </div>
                <div className="col-6 text-end">
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                    <g id="Union_4" data-name="Union 4" fill="none">
                      <path d="M0,9A9,9,0,0,1,9,0h9V9A9,9,0,1,1,0,9Z" stroke="none"/>
                      <path d="M 8.999960899353027 2 C 5.140171051025391 2 2 5.140171051025391 2 8.999960899353027 C 2 12.85996055603027 5.140171051025391 16.00030136108398 8.999960899353027 16.00030136108398 C 12.85996055603027 16.00030136108398 16.00030136108398 12.85996055603027 16.00030136108398 8.999960899353027 L 16.00030136108398 2 L 8.999960899353027 2 M 8.999960899353027 0 L 18.00030136108398 0 L 18.00030136108398 8.999960899353027 C 18.00030136108398 13.97070121765137 13.97070121765137 18.00030136108398 8.999960899353027 18.00030136108398 C 4.029590606689453 18.00030136108398 0 13.97070121765137 0 8.999960899353027 C 0 4.029590606689453 4.029590606689453 0 8.999960899353027 0 Z" stroke="none" fill="#0793bf"/>
                    </g>
                  </svg>
                </div>
                <div className="col-12 mt-2">
                  <Link to={messageUrl} dangerouslySetInnerHTML={{__html: messageSubject}}></Link> 
                  <p dangerouslySetInnerHTML={{__html: messageBody}}></p>
                </div>
                <div className="col-12 mt-2">
                  <div className="notificationTime">30 m</div>
                </div>
              </div>
            </div>

            



          </div>
        </div>

      )
    }


    

  }


  render() { 

    let messageAttachment = this.props.messageAttachment
    let messageBody = this.props.messageBody
    let messageSubject = this.props.messageSubject
    let messageUrl = this.props.messageUrl
    let notificationType = this.props.notificationType
    let orderId = this.props.orderId
    let orderReference = this.props.orderReference
    let orderStatus = this.props.orderStatus
    let dateSent = this.props.dateSent;
    let userRead = this.props.userRead;

    return (  
      <>
        {this.displayNotification(messageAttachment,messageBody,messageSubject,messageUrl,notificationType,orderId,orderReference,orderStatus, dateSent, userRead)}
      </>
    )
  }
}

const mapStateToProps = (state, ownProps) => {

  const { 
    authentication, 
    user,
  } = state
 

  return {
    authentication, 
    user, 
  }
}

export default withRouter(connect(mapStateToProps, { 
})(PartnerHeaderNotification))
