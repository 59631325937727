

export const clearCache = async ({store, force = false, forceRefresh = true} = {}) => {
  const token = localStorage.getItem("token");
  const orderCookieId = localStorage.getItem("orderCookieId");
  const voucher = localStorage.getItem("voucher");
  const voucherStatus = localStorage.getItem("voucherStatus");
  const basket = localStorage.getItem("basket");
  let partnerPortalId = localStorage.getItem("partnerPortalId");

  if(partnerPortalId === "undefined") {
    localStorage.removeItem("partnerPortalId");
    partnerPortalId = 0
  }

  const isBiomarkerTracking = window.location.hostname.includes("biomarkertracking")
  try {
    localStorage.clear();

    const cookies = document.cookie;
    for (const cookie of cookies.split(";")) {
      const [name, value] = cookie.split("=");
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }

    if ("caches" in window) {
      caches
        .keys()
        .then(function (cacheNames) {
          cacheNames?.forEach(function (cacheName) {
            caches.delete(cacheName);
          });
        })
        .catch(function (error) {
          console.error("Error:", error);
        });
    }

    store.dispatch({type: "CLEAR_GLOBAL_STATE"})

    Object.entries({token, orderCookieId, voucher, voucherStatus, basket}).forEach(([key, value]) => {
      if(value) {
        localStorage.setItem(key, value);

        if(key === "token") {
          store.getState().authentication.isAuthenticated = true
          store.getState().authentication.token = value
        }
      }
    })

    if(isBiomarkerTracking && partnerPortalId) {
      localStorage.setItem("partnerPortalId", partnerPortalId);
    }

    return Promise.resolve(true);
  } catch (error) {
    return Promise.reject(new Error(error));
  }
};
