import React, {Component} from 'react'; 
import PropTypes from 'prop-types'
import { connect, Provider } from 'react-redux'
import { Route, Switch, withRouter } from 'react-router-dom'
import { loadLatestOrderStatus, loadLastOrderDate } from '../actions/order-actions'

class Footer extends Component {

  constructor(props) {
    super(props); 
  }

  static propTypes = {
    authentication: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired, 
    loadLatestOrderStatus: PropTypes.func.isRequired,
    loadLastOrderDate: PropTypes.func.isRequired,
  }

  componentWillMount() { 
    this.props.loadLatestOrderStatus('me');
    this.props.loadLastOrderDate('me');
  }

  render() {
    var url = '';
    var androidAppUrl = '';
    var iosAppUrl = '';

    var isIosWebview =/(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(navigator.userAgent)
    var ua = navigator.userAgent.toLowerCase();
    var isAndroid = window.isRNWebView;

    if (window.location.hostname == 'app.forthwithlife.co.uk' || window.location.hostname == 'shop.forthwithlife.co.uk') {
      url = 'https://www.forthwithlife.co.uk';
      androidAppUrl = 'https://play.google.com/store/apps/details?id=com.forthlife';
      iosAppUrl = 'https://apps.apple.com/us/app/forth-life/id1463063574?ls=1';
    } else {
      url = 'https://www.sportsbloodtests.co.uk'
      androidAppUrl = 'https://play.google.com/store/apps/details?id=com.forthedge'
      iosAppUrl = 'https://apps.apple.com/gb/app/forth-edge/id1468480957';
    }



    const order = this.props.order;
    const lastOrder = this.props.lastOrder;
    var orderId = Object.keys(order)[0];
    var lastOrderId = Object.keys(lastOrder)[0];
    var partnerId = 0;
    var logo; 

    if (Object.keys(order).length > 0) {
      partnerId = order[orderId].partnerId; 
    } 

    if (Object.keys(lastOrder).length > 0 && lastOrder[lastOrderId].partnerId != 0) {
      partnerId = lastOrder[lastOrderId].partnerId; 
    }


    if (partnerId != 0) {
      
      logo = <div>
                <img width="110" src="img/fullwhite.png" />
             </div>
                     
    }  

    return (
      <footer>
        {!isAndroid && !isIosWebview && partnerId == 0 ? (
          <section className="row justify-content-center align-items-center app-section">
            <p className="col-12 col-md-auto mb-0 text-center">Get the app</p>
            <a target="_blank" href={iosAppUrl} className="col-auto">
              <img src="/img/iOSAppStoreM.png" />
            </a>
            <a target="_blank" href={androidAppUrl} className="col-auto">
              <img src="/img/androidAppStoreM.png" />
            </a>
          </section>
        ) : null}

        <div className="container">
          <section className="row align-items-center justify-content-between disclaimer-section">
            <div className="col-12">
              <p className="px-3 pt-5 mb-0 text-center">Forth’s service is intended to enable people to improve wellbeing and optimise sports performance. It is not intended for clinical diagnosis. Test results are for information purposes only. If you do have concerns about your health, please discuss these directly with your GP.</p>
            </div>
          </section>

          <section className="row align-items-center justify-content-between">
            <div className="col-12 py-5 certified-section">
              <i className="fas fa-shield-check fa-fw fa-2x"></i>
              <p className='mb-0 px-2'>Our labs are accredited by CPA and UKAS</p>
            </div>
          </section>

        </div>

        <section className="terms-section">
          <div className="container">
            <div className="row gy-3 mb-md-4 align-items-center justify-content-center">
              {!isAndroid && !isIosWebview && (
                <>
                  <div className="col-12 col-md-auto text-center">
                    <a href={url + '/terms-of-service/'} target="_blank">Terms of service</a>
                  </div>

                  {global.config.websiteId == 1 &&
                    <div className="col-12 col-md-auto text-center">
                      <a href="https://www.forthwithlife.co.uk/offers-tc/" target="_blank">Offers T&amp;C</a>
                    </div>
                  } 

                  <div className="col-12 col-md-auto text-center">
                    <a href={url + '/privacy-policy/'} target="_blank">Privacy policy</a>
                  </div>

                  <div className="col-12 col-md-auto text-center">
                    <a href={url + '/cookie-policy/'} target="_blank">Cookie policy</a>
                  </div>
                </>
              )}
            </div>
          </div>
        </section>

        <hr className="mx-auto my-5"/>

        <section className="company-section row mb-lg-3">
          <div className="col-12 col-md-6 col-lg-5 order-lg-1 h-100 d-none d-md-block">
            {partnerId == 0 ? ( 
              <div className="row align-items-center">
                <p className="col-auto mb-0">Backed and supported by</p>
                <img src="/img/banc-logo.png" className="col-auto" style={{maxHeight: "50px"}}/>
                <img src="/img/euro-logo.png" className="col-auto" style={{maxHeight: "50px"}}/>
              </div>
            ) : (
              <div className="col-12 col-md-2 ps-md-5 d-md-block">
                <img src="/img/poweredbyForth.png"/>
              </div>
            )}
          </div>

          <div className="col-12 col-md-6 col-lg-5 order-lg-3">
            <div className="row align-items-center justify-content-center h-100">
              <div className="col-12 col-md-auto text-center">
                <img src="/img/forth-black.png" alt="forth logo" className="forth-logo"/>
              </div>
              <div className="company-address row flex-column mt-3 mt-md-0 col-12 col-md-auto d-none d-md-flex">
                <p className="mb-0 col-12">Basepoint Business Centre</p>
                <p className="mb-0 col-12">Beaufort Park, Chepstow, NP16 5UH</p>
              </div>
              <div className="company-address row mt-3 mt-md-0 mb-4 col-12 col-md-auto d-md-none text-center">
                <p className="mb-0 col-12">Basepoint Business Centre</p>
                <p className="mb-0 col-12">Beaufort Park</p>
                <p className="mb-0 col-12">Chepstow</p>
                <p className="mb-0 col-12">NP16 5UH</p>
              </div>
            </div>
          </div>

          <div className="col order-3 order-lg-2 my-4 company-name text-center">
            &copy;{new Date().getFullYear()} Humankind Ventures Ltd.
          </div>
        </section>

        <div className="modal forthModal" id="benefitsModal" tabIndex="-1" role="dialog" aria-labelledby="benefitsModalLabel" aria-hidden="true">

          <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>

          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="benefitsModalLabel">The <span className="thickText">benefits</span> of regular testing</h5>              
              </div>
              <div className="modal-body text-center">
                
                <p><img src="/img/benefitsModalGraphic.png" alt="the benefits of regular testing" className="contrast" /></p>
                <p>By tracking your markers over time you can build a picture of your own unique biomarker profile, discover what lifestyle changes works best for you and get an early warning if things start to go wrong. Our market leading biomarker dashboard tracks your progress over time, gives you simplified information on each marker as well as tips on how to improve.</p>
                
              </div>
              <div className="modal-footer mb-3">
                <button type="button" className="btn btn-primary" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}


const mapStateToProps = (state, ownProps) => {

  const {
    entities: { order, lastOrder },
    authentication, 
    user
  } = state
 

  return {
    authentication, 
    user,
    order,
    lastOrder
  }
}

export default withRouter(connect(mapStateToProps, { 
  loadLatestOrderStatus,
  loadLastOrderDate
})(Footer))