import { CALL_API, Schemas } from '../middleware/api'



export const USER_REQUEST = 'USER_REQUEST'
export const USER_SUCCESS = 'USER_SUCCESS'
export const USER_FAILURE = 'USER_FAILURE'

const fetchUser = (userId) => ({
  userId,
  [CALL_API]: {
    types: [ USER_REQUEST, USER_SUCCESS, USER_FAILURE ],
    endpoint: `secure/partners/${userId}`,
    schema: userId === 'me' ? null : Schemas.USER
  }
})

export const loadPartnerUser = (userId) => (dispatch, getState) => {
  const user = getState().entities.users[userId];
  if (user) {
    return null
  }

  return dispatch(fetchUser(userId))
}


export const TOKEN_REQUEST = 'TOKEN_REQUEST';
export const TOKEN_SUCCESS = 'TOKEN_SUCCESS';
export const TOKEN_FAILURE = 'TOKEN_FAILURE';

const fetchToken = (username, password, ip, siteId, customerPlatform, partner, appVersion) => ({
  username,
  password,
  ip,
  siteId,
  customerPlatform,
  appVersion,
  [CALL_API]: {
    types: [TOKEN_REQUEST, TOKEN_SUCCESS, TOKEN_FAILURE],
    endpoint: 'partner-login',
    method: 'POST',
    body: {
      username,
      password,
      ip,
      siteId,
      customerPlatform,
      partner,
      appVersion
    },
  },
});

export const loadToken = (username, password, ip, siteId, customerPlatform, partner, appVersion) => (dispatch) => {
  return dispatch(fetchToken(username, password, ip, siteId, customerPlatform, partner, appVersion));
};

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

const fetchReset = (email, siteId) => ({
  email, 
  siteId,
  [CALL_API]: {
    types: [ RESET_PASSWORD_REQUEST, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAILURE ],
    endpoint: `partner-reset-password`,
    method: 'POST',
    body: {
      emailAddress: email,
      siteId: siteId
    }
  }
})
 
export const doReset = (email, siteId) => (dispatch) => {
  return dispatch(fetchReset(email, siteId));
};

export const TOKEN_CHECK_REQUEST = 'TOKEN_CHECK_REQUEST'
export const TOKEN_CHECK_SUCCESS = 'TOKEN_CHECK_SUCCESS'
export const TOKEN_CHECK_FAILURE = 'TOKEN_CHECK_FAILURE'

const fetchResetToken = (token) => ({
  token,
  [CALL_API]: {
    types: [ TOKEN_CHECK_REQUEST, TOKEN_CHECK_SUCCESS, TOKEN_CHECK_FAILURE ],
    endpoint: `partner-check-reset-token/${token}`,
    schema: null
  }
})

export const checkResetToken = (token) => (dispatch, getState) => {
  return dispatch(fetchResetToken(token))
};

export const PASSWORD_UPDATE_REQUEST = 'PASSWORD_UPDATE_REQUEST'
export const PASSWORD_UPDATE_SUCCESS = 'PASSWORD_UPDATE_SUCCESS'
export const PASSWORD_UPDATE_FAILURE = 'PASSWORD_UPDATE_FAILURE'

const fetchUpdatePassword = (password, partnerUserId, token) => ({
  password,
  partnerUserId,
  token,
  [CALL_API]: {
    types: [PASSWORD_UPDATE_REQUEST, PASSWORD_UPDATE_SUCCESS, PASSWORD_UPDATE_FAILURE],
    endpoint: '/update-partner-password',
    method: 'POST',
    body: {
      password,
      partnerUserId,
      token
    },
  },
});

export const loadUpdatePassword = (password, partnerUserId, token) => (dispatch) => {
  return dispatch(fetchUpdatePassword(password, partnerUserId, token));
};

export const PARTNER_REQUEST = 'PARTNER_REQUEST'
export const PARTNER_SUCCESS = 'PARTNER_SUCCESS'
export const PARTNER_FAILURE = 'PARTNER_FAILURE'

const fetchPartnerDetails = (partnerId) => ({
  partnerId,
  [CALL_API]: {
    types: [ PARTNER_REQUEST, PARTNER_SUCCESS, PARTNER_FAILURE ],
    endpoint: `secure/partners/partner-details/${partnerId}`,
    schema: Schemas.PARTNER
  }
})

export const loadPartnerDetails = (partnerId) => (dispatch, getState) => {
  const partner = getState().entities.partner;

/*   if (Object.keys(partner).length > 0) {
    Object.keys(partner).map(function(key) {

      delete partner[key];

    })  
  }  */
  return dispatch(fetchPartnerDetails(partnerId))
}

const fetchPartnerDetailsUnsecure = (partnerId) => ({
  partnerId,
  [CALL_API]: {
    types: [ PARTNER_REQUEST, PARTNER_SUCCESS, PARTNER_FAILURE ],
    endpoint: `/partners/partner-details/${partnerId}`,
    schema: Schemas.PARTNER
  }
})

export const loadPartnerDetailsUnsecure = (partnerId) => (dispatch, getState) => {
  const partner = getState().entities.partner;

/*   if (Object.keys(partner).length > 0) {
    Object.keys(partner).map(function(key) {

      delete partner[key];

    })  
  }  */
  return dispatch(fetchPartnerDetailsUnsecure(partnerId))
}

const fetchPartnerDetailsSignUp = (signUp_Id) => ({
  signUp_Id,
  [CALL_API]: {
    types: [ PARTNER_REQUEST, PARTNER_SUCCESS, PARTNER_FAILURE ],
    endpoint: `partners/sign-up-partner-details/${signUp_Id}`,
    schema: Schemas.PARTNER
  }
})

export const loadPartnerDetailsSignUp = (signUp_Id) => (dispatch, getState) => {
  const partner = getState().entities.partner;

  if (Object.keys(partner).length > 0) {
    Object.keys(partner).map(function(key) {

      delete partner[key];

    })  
  } 
  return dispatch(fetchPartnerDetailsSignUp(signUp_Id))
}


export const PARTNER_ACCESS_UPDATE_REQUEST = 'PARTNER_ACCESS_UPDATE_REQUEST'
export const PARTNER_ACCESS_UPDATE_SUCCESS = 'PARTNER_ACCESS_UPDATE_SUCCESS'
export const PARTNER_ACCESS_UPDATE_FAILURE = 'PARTNER_ACCESS_UPDATE_FAILURE'

const fetchUpdatePartnerAccess = (body) => ({
  [CALL_API]: {
    types: [ PARTNER_ACCESS_UPDATE_REQUEST, PARTNER_ACCESS_UPDATE_SUCCESS, PARTNER_ACCESS_UPDATE_FAILURE ],
    endpoint: 'secure/partners/partner-access',
    schema: null,
    method: 'POST', 
    body: body
  }
})

export const updatePartnerAccess  = (body) => (dispatch, getState) => {
  return dispatch(fetchUpdatePartnerAccess(body))
};


export const PARTNER_ACCESS_REVOKE_REQUEST = 'PARTNER_ACCESS_REVOKE_REQUEST'
export const PARTNER_ACCESS_REVOKE_SUCCESS = 'PARTNER_ACCESS_REVOKE_SUCCESS'
export const PARTNER_ACCESS_REVOKE_FAILURE = 'PARTNER_ACCESS_REVOKE_FAILURE'

const fetchRevokePartnerAccess = (body) => ({
  [CALL_API]: {
    types: [ PARTNER_ACCESS_REVOKE_REQUEST, PARTNER_ACCESS_REVOKE_SUCCESS, PARTNER_ACCESS_REVOKE_FAILURE ],
    endpoint: 'secure/partners/revoke-partner',
    schema: null,
    method: 'POST', 
    body: body
  }
})

export const revokePartnerAccess  = (body) => (dispatch, getState) => {
  return dispatch(fetchRevokePartnerAccess(body))
};


export const PARTNER_ACCESS_APPROVE_REQUEST = 'PARTNER_ACCESS_APPROVE_REQUEST'
export const PARTNER_ACCESS_APPROVE_SUCCESS = 'PARTNER_ACCESS_APPROVE_SUCCESS'
export const PARTNER_ACCESS_APPROVE_FAILURE = 'PARTNER_ACCESS_APPROVE_FAILURE'

const fetchApprovePartnerAccess = (body) => ({
  [CALL_API]: {
    types: [ PARTNER_ACCESS_APPROVE_REQUEST, PARTNER_ACCESS_APPROVE_SUCCESS, PARTNER_ACCESS_APPROVE_FAILURE ],
    endpoint: 'secure/partners/approve-partner',
    schema: null,
    method: 'POST', 
    body: body
  }
})

export const approvePartnerAccess  = (body) => (dispatch, getState) => {
  return dispatch(fetchApprovePartnerAccess(body))
};


export const PARTNER_AWAITING_ACCESS_REVOKE_REQUEST = 'PARTNER_AWAITING_ACCESS_REVOKE_REQUEST'
export const PARTNER_AWAITING_ACCESS_REVOKE_SUCCESS = 'PARTNER_AWAITING_ACCESS_REVOKE_SUCCESS'
export const PARTNER_AWAITING_ACCESS_REVOKE_FAILURE = 'PARTNER_AWAITING_ACCESS_REVOKE_FAILURE'

const fetchRevokeAwaitingPartnerAccess = (body) => ({
  [CALL_API]: {
    types: [ PARTNER_AWAITING_ACCESS_REVOKE_REQUEST, PARTNER_AWAITING_ACCESS_REVOKE_SUCCESS, PARTNER_AWAITING_ACCESS_REVOKE_FAILURE ],
    endpoint: 'secure/partners/revoke-awaiting-partner',
    schema: null,
    method: 'POST', 
    body: body
  }
})

export const revokeAwaitingPartnerAccess  = (body) => (dispatch, getState) => {
  return dispatch(fetchRevokeAwaitingPartnerAccess(body))
};



export const PARTNER_ACCESS_CODE_REQUEST = 'PARTNER_ACCESS_CODE_REQUEST'
export const PARTNER_ACCESS_CODE_SUCCESS = 'PARTNER_ACCESS_CODE_SUCCESS'
export const PARTNER_ACCESS_CODE_FAILURE = 'PARTNER_ACCESS_CODE_FAILURE'

const fetchPartnerAccessCode = (body) => ({
  [CALL_API]: {
    types: [ PARTNER_ACCESS_CODE_REQUEST, PARTNER_ACCESS_CODE_SUCCESS, PARTNER_ACCESS_CODE_FAILURE ],
    endpoint: 'secure/partners/access-code',
    schema: null,
    method: 'POST', 
    body: body
  }
})

export const partnerAccessCode  = (body) => (dispatch, getState) => {
  return dispatch(fetchPartnerAccessCode(body))
};

export const ADD_PARTNER_COMPANY_REQUEST = 'ADD_PARTNER_COMPANY_REQUEST'
export const ADD_PARTNER_COMPANY_SUCCESS = 'ADD_PARTNER_COMPANY_SUCCESS'
export const ADD_PARTNER_COMPANY_FAILURE = 'ADD_PARTNER_COMPANY_FAILURE'

const fetchAddPartnerCompany = (body) => ({
  [CALL_API]: {
    types: [ ADD_PARTNER_COMPANY_REQUEST, ADD_PARTNER_COMPANY_SUCCESS, ADD_PARTNER_COMPANY_FAILURE ],
    endpoint: 'partners/add-partner-company',
    schema: null,
    method: 'POST', 
    body: body
  }
})

export const addPartnerCompany  = (body) => (dispatch, getState) => {
  return dispatch(fetchAddPartnerCompany(body))
};

export const ADD_PARTNER_ADMIN_REQUEST = 'ADD_PARTNER_ADMIN_REQUEST'
export const ADD_PARTNER_ADMIN_SUCCESS = 'ADD_PARTNER_ADMIN_SUCCESS'
export const ADD_PARTNER_ADMIN_FAILURE = 'ADD_PARTNER_ADMIN_FAILURE'

const fetchAddPartnerAdmin = (body) => ({
  [CALL_API]: {
    types: [ ADD_PARTNER_COMPANY_REQUEST, ADD_PARTNER_COMPANY_SUCCESS, ADD_PARTNER_COMPANY_FAILURE ],
    endpoint: 'partners/add-partner-admin',
    schema: null,
    method: 'POST', 
    body: body
  }
})

export const addPartnerAdmin = (body) => (dispatch, getState) => {
  return dispatch(fetchAddPartnerAdmin(body))
};

export const UPDATE_PARTNER_ADMIN_REQUEST = 'UPDATE_PARTNER_ADMIN_REQUEST'
export const UPDATE_PARTNER_ADMIN_SUCCESS = 'UPDATE_PARTNER_ADMIN_SUCCESS'
export const UPDATE_PARTNER_ADMIN_FAILURE = 'UPDATE_PARTNER_ADMIN_FAILURE'

const fetchUpdatePartnerAdmin = (body) => ({
  [CALL_API]: {
    types: [ UPDATE_PARTNER_ADMIN_REQUEST, UPDATE_PARTNER_ADMIN_SUCCESS, UPDATE_PARTNER_ADMIN_FAILURE ],
    endpoint: 'secure/partners/update-partner-admin',
    schema: null,
    method: 'POST', 
    body: body
  }
})

export const updatePartnerAdmin = (body) => (dispatch, getState) => {
  return dispatch(fetchUpdatePartnerAdmin(body))
};

export const UPLOAD_PARTNER_IMAGE_REQUEST = 'UPLOAD_PARTNER_IMAGE_REQUEST'
export const UPLOAD_PARTNER_IMAGE_SUCCESS = 'UPLOAD_PARTNER_IMAGE_SUCCESS'
export const UPLOAD_PARTNER_IMAGE_FAILURE = 'UPLOAD_PARTNER_IMAGE_FAILURE'

const fetchUpdatePartnerProfilePicture = (body) => ({
  [CALL_API]: {
    types: [ UPLOAD_PARTNER_IMAGE_REQUEST, UPLOAD_PARTNER_IMAGE_SUCCESS, UPLOAD_PARTNER_IMAGE_FAILURE ],
    endpoint: 'partners/upload-partner-profile-picture',
    schema: null,
    method: 'POST', 
    body: body
  }
})

export const updatePartnerProfilePicture  = (body) => (dispatch, getState) => {
  return dispatch(fetchUpdatePartnerProfilePicture(body))
};

export const ADD_PARTNER_TESTS_REQUEST = 'ADD_PARTNER_TESTS_REQUEST'
export const ADD_PARTNER_TESTS_SUCCESS = 'ADD_PARTNER_TESTS_SUCCESS'
export const ADD_PARTNER_TESTS_FAILURE = 'ADD_PARTNER_TESTS_FAILURE'

const fetchAddPartnerTests = (body) => ({
  [CALL_API]: {
    types: [ ADD_PARTNER_TESTS_REQUEST, ADD_PARTNER_TESTS_SUCCESS, ADD_PARTNER_TESTS_FAILURE ],
    endpoint: 'partners/add-partner-tests',
    schema: null,
    method: 'POST', 
    body: body
  }
})

export const addPartnerTests  = (body) => (dispatch, getState) => {
  return dispatch(fetchAddPartnerTests(body))
};

export const ADD_PARTNER_TERMS_REQUEST = 'ADD_PARTNER_TERMS_REQUEST'
export const ADD_PARTNER_TERMS_SUCCESS = 'ADD_PARTNER_TERMS_SUCCESS'
export const ADD_PARTNER_TERMS_FAILURE = 'ADD_PARTNER_TERMS_FAILURE'

const fetchPartnerSaveTandCss = (body) => ({
  [CALL_API]: {
    types: [ ADD_PARTNER_TERMS_REQUEST, ADD_PARTNER_TERMS_SUCCESS, ADD_PARTNER_TERMS_FAILURE ],
    endpoint: 'partners/partner-terms-conditions',
    schema: null,
    method: 'POST', 
    body: body
  }
})

export const partnerSaveTandCs  = (body) => (dispatch, getState) => {
  return dispatch(fetchPartnerSaveTandCss(body))
};

export const PARTNER_AREAS_OF_BUSINESS_REQUEST = 'PARTNER_AREAS_OF_BUSINESS_REQUEST'
export const PARTNER_AREAS_OF_BUSINESS_SUCCESS = 'PARTNER_AREAS_OF_BUSINESS_SUCCESS'
export const PARTNER_AREAS_OF_BUSINESS_FAILURE = 'PARTNER_AREAS_OF_BUSINESS_FAILURE'

const fetchAreasOfBusiness = () => ({
  [CALL_API]: {
    types: [ PARTNER_AREAS_OF_BUSINESS_REQUEST, PARTNER_AREAS_OF_BUSINESS_SUCCESS, PARTNER_AREAS_OF_BUSINESS_FAILURE ],
    endpoint: 'partners/get-areas-of-business',
    schema: Schemas.GET_AREA_OF_BUSINESS,
  }
})

export const getAreasOfBusiness  = () => (dispatch, getState) => {
  return dispatch(fetchAreasOfBusiness())
};

export const PARTNER_SIGNUP_DETAILS_REQUEST = 'PARTNER_SIGNUP_DETAILS_REQUEST'
export const PARTNER_SIGNUP_DETAILS_SUCCESS = 'PARTNER_SIGNUP_DETAILS_SUCCESS'
export const PARTNER_SIGNUP_DETAILS_FAILURE = 'PARTNER_SIGNUP_DETAILS_FAILURE'

const fetchPartnerSignupDetails = (slugId) => ({
  [CALL_API]: {
    types: [ PARTNER_SIGNUP_DETAILS_REQUEST, PARTNER_SIGNUP_DETAILS_SUCCESS, PARTNER_SIGNUP_DETAILS_FAILURE ],
    endpoint: `partners/get-sign-up-details/${slugId}`,
    schema: Schemas.PARTNER_SIGN_UP,
  }
})

export const getPartnerSignupDetails = (slugId) => (dispatch, getState) => {
  return dispatch(fetchPartnerSignupDetails(slugId))
};

export const PARTNER_USER_REQUEST = 'PARTNER_USER_REQUEST'
export const PARTNER_USER_SUCCESS = 'PARTNER_USER_SUCCESS'
export const PARTNER_USER_FAILURE = 'PARTNER_USER_FAILURE'

const fetchPartnerUserDetails = (userId) => ({
  [CALL_API]: {
    types: [ PARTNER_USER_REQUEST, PARTNER_USER_SUCCESS, PARTNER_USER_FAILURE ],
    endpoint: `secure/partners/get-partner-user/${userId}`,
    schema: Schemas.GET_PARTNER_USERS,
  }
})

export const getPartnerUserDetails = (userId) => (dispatch, getState) => {
  return dispatch(fetchPartnerUserDetails(userId))
};

const fetchPartnerUserDetailsSignUp = (signUp_Id) => ({
  [CALL_API]: {
    types: [ PARTNER_USER_REQUEST, PARTNER_USER_SUCCESS, PARTNER_USER_FAILURE ],
    endpoint: `partners/sign-up-get-partner-user/${signUp_Id}`,
    schema: Schemas.GET_PARTNER_USERS,
  }
})

export const getPartnerUserDetailsSignUp = (signUp_Id) => (dispatch, getState) => {
  return dispatch(fetchPartnerUserDetailsSignUp(signUp_Id))
};


export const ADD_TEST_REQUEST = 'ADD_TEST_REQUEST'
export const ADD_TEST_SUCCESS = 'ADD_TEST_SUCCESS'
export const ADD_TEST_FAILURE = 'ADD_TEST_FAILURE'

const fecthAddPartnerTest = (body) => ({
  body, 
  [CALL_API]: {
    types: [ ADD_TEST_REQUEST, ADD_TEST_SUCCESS, ADD_TEST_FAILURE ],
    endpoint: `/partners/add-partner-test`,
    schema: null,
    body: body,
    method: 'POST', 
  }
})

export const addPartnerTest = (body) => (dispatch, getState) => {
  return dispatch(fecthAddPartnerTest(body))
};

export const PARTNER_ORDERS_REQUEST = 'PARTNER_ORDERS_REQUEST'
export const PARTNER_ORDERS_SUCCESS = 'PARTNER_ORDERS_SUCCESS'
export const PARTNER_ORDERS_FAILURE = 'PARTNER_ORDERS_FAILURE'

const fetchPartnerOrderDetails = (partnerId, orderId, limit, orderLimit) => ({
  [CALL_API]: {
    types: [ PARTNER_ORDERS_REQUEST, PARTNER_ORDERS_SUCCESS, PARTNER_ORDERS_FAILURE ],
    endpoint: `secure/partners/get-partner-orders/${partnerId}/${orderId}/${limit}/${orderLimit}`,
    schema: Schemas.GET_PARTNER_ORDERS,
  }
})

export const getPartnerOrderDetails = (partnerId, orderId=0, limit=0, orderLimit=0) => (dispatch, getState) => {
  return dispatch(fetchPartnerOrderDetails(partnerId, orderId, limit, orderLimit))
};

export const PARTNER_CUSTOMERS_REQUEST = 'PARTNER_CUSTOMERS_REQUEST'
export const PARTNER_CUSTOMERS_SUCCESS = 'PARTNER_CUSTOMERS_SUCCESS'
export const PARTNER_CUSTOMERS_FAILURE = 'PARTNER_CUSTOMERS_FAILURE'

const fetchPartnerCustomerDetails = (partnerId, userId, accessStatus) => ({
  [CALL_API]: {
    types: [ PARTNER_CUSTOMERS_REQUEST, PARTNER_CUSTOMERS_SUCCESS, PARTNER_CUSTOMERS_FAILURE ],
    endpoint: `secure/partners/get-partner-customers/${partnerId}/${userId}/${accessStatus}`,
    schema: Schemas.GET_PARTNER_CUSTOMERS,
  }
})

export const getPartnerCustomerDetails = (partnerId, userId=0, accessStatus = undefined) => (dispatch, getState) => {
  return dispatch(fetchPartnerCustomerDetails(partnerId, userId, accessStatus))
};



export const PARTNER_NOTIFICATIONS_REQUEST = 'PARTNER_NOTIFICATIONS_REQUEST'
export const PARTNER_NOTIFICATIONS_SUCCESS = 'PARTNER_NOTIFICATIONS_SUCCESS'
export const PARTNER_NOTIFICATIONS_FAILURE = 'PARTNER_NOTIFICATIONS_FAILURE'

const fetchPartnerNotifications = (limit) => ({
  [CALL_API]: {
    types: [ PARTNER_NOTIFICATIONS_REQUEST, PARTNER_NOTIFICATIONS_SUCCESS, PARTNER_NOTIFICATIONS_FAILURE ],
    endpoint: `secure/partners/get-notifications/${limit}`,
    schema: Schemas.PARTNER_NOTIFICATIONS,
  }
})

export const getPartnerNotifications = (limit) => (dispatch, getState) => {
  return dispatch(fetchPartnerNotifications(limit))
};


export const UPDATE_PARTNER_NOTIFICATION_REQUEST = 'UPDATE_PARTNER_NOTIFICATION_REQUEST'
export const UPDATE_PARTNER_NOTIFICATION_SUCCESS = 'UPDATE_PARTNER_NOTIFICATION_SUCCESS'
export const UPDATE_PARTNER_NOTIFICATION_FAILURE = 'UPDATE_PARTNER_NOTIFICATION_FAILURE'

const fetchUpdatePartnerNotifications = (body) => ({
  body, 
  [CALL_API]: {
    types: [ UPDATE_PARTNER_NOTIFICATION_REQUEST, UPDATE_PARTNER_NOTIFICATION_SUCCESS, UPDATE_PARTNER_NOTIFICATION_FAILURE ],
    endpoint: `secure/partners/update-notifications`,
    schema: null,
    body: body,
    method: 'POST', 
  }
})

export const updatePartnerNotifications = (body) => (dispatch, getState) => {
  return dispatch(fetchUpdatePartnerNotifications(body))
};


export const UPDATE_CLIENT_REQUEST = 'UPDATE_CLIENT_REQUEST'
export const UPDATE_CLIENT_SUCCESS = 'UPDATE_CLIENT_SUCCESS'
export const UPDATE_CLIENT_FAILURE = 'UPDATE_CLIENT_FAILURE'

const fetchUpdateClient = (body) => ({
  body,
  [CALL_API]: {
    types: [UPDATE_CLIENT_REQUEST, UPDATE_CLIENT_SUCCESS, UPDATE_CLIENT_FAILURE],
    endpoint: `secure/partners/update-clients`,
    schema: null, 
    method: 'POST',
    body: body
  }
})

export const updateClient = (body) => (dispatch, getState) => {
  return dispatch(fetchUpdateClient(body))
}

export const GET_PARTNERCODE_REQUEST = 'GET_PARTNERCODE_REQUEST'
export const GET_PARTNERCODE_SUCCESS = 'GET_PARTNERCODE_SUCCESS'
export const GET_PARTNERCODE_FAILURE = 'GET_PARTNERCODE_FAILURE'

const fetchGetPartnerCode = (partnerCode) => ({
  partnerCode,
  [CALL_API]: {
    types: [ GET_PARTNERCODE_REQUEST, GET_PARTNERCODE_SUCCESS, GET_PARTNERCODE_FAILURE ],
    endpoint: `partners/partner-by-code/${partnerCode}`,
    schema: Schemas.PARTNER
  }
})

export const loadPartnerCode  = (partnerCode) => (dispatch, getState) => {
  return dispatch(fetchGetPartnerCode(partnerCode))
};

export const GET_PARTNERTESTS_REQUEST = 'GET_PARTNERTESTS_REQUEST'
export const GET_PARTNERTESTS_SUCCESS = 'GET_PARTNERTESTS_SUCCESS'
export const GET_PARTNERTESTS_FAILURE = 'GET_PARTNERTESTS_FAILURE'

const fetchGetPartnerTest = (partnerId) => ({
  partnerId,
  [CALL_API]: {
    types: [ GET_PARTNERTESTS_REQUEST, GET_PARTNERTESTS_SUCCESS, GET_PARTNERTESTS_FAILURE ],
    endpoint: `/partners/get-tests/${partnerId}`,
    schema: Schemas.TESTS
  }
})

export const loadPartnerTests  = (partnerId) => (dispatch, getState) => {
  return dispatch(fetchGetPartnerTest(partnerId))
};



export const GET_All_PARTNER_TESTS_REQUEST = 'GET_All_PARTNER_TESTS_REQUEST'
export const GET_All_PARTNER_TESTS_SUCCESS = 'GET_All_PARTNER_TESTS_SUCCESS'
export const GET_All_PARTNER_TESTS_FAILURE = 'GET_All_PARTNER_TESTS_FAILURE'

const fetchAllPartnerTest = (requestCode) => ({
  requestCode,
  [CALL_API]: {
    types: [ GET_All_PARTNER_TESTS_REQUEST, GET_All_PARTNER_TESTS_SUCCESS, GET_All_PARTNER_TESTS_FAILURE ],
    endpoint: `/partners/get-all-partner-tests/${requestCode}`,
    schema: Schemas.PARTNER_TESTS_ARRAY
  }
})

export const loadAllPartnerTests  = (requestCode) => (dispatch, getState) => {
  return dispatch(fetchAllPartnerTest(requestCode))
};

export const UPDATE_COMPANY_REQUEST = 'UPDATE_COMPANY_REQUEST'
export const UPDATE_COMPANY_SUCCESS = 'UPDATE_COMPANY_SUCCESS'
export const UPDATE_COMPANY_FAILURE = 'UPDATE_COMPANY_FAILURE'

const fetchUpdateCompany = (body) => ({
  body,
  [CALL_API]: {
    types: [UPDATE_COMPANY_REQUEST, UPDATE_COMPANY_SUCCESS, UPDATE_COMPANY_FAILURE],
    endpoint: `secure/partners/update-company-settings`,
    schema: null, 
    method: 'POST',
    body: body
  }
})

export const updateCompany = (body) => (dispatch, getState) => {
  return dispatch(fetchUpdateCompany(body))
}

export const UPDATE_PARTNER_TEST_REQUEST = 'UPDATE_PARTNER_TEST_REQUEST'
export const UPDATE_PARTNER_TEST_SUCCESS = 'UPDATE_PARTNER_TEST_SUCCESS'
export const UPDATE_PARTNER_TEST_FAILURE = 'UPDATE_PARTNER_TEST_FAILURE'

const fetchUpdateTest = (body) => ({
  body,
  [CALL_API]: {
    types: [UPDATE_PARTNER_TEST_REQUEST, UPDATE_PARTNER_TEST_SUCCESS, UPDATE_PARTNER_TEST_FAILURE],
    endpoint: `secure/partners/update-test`,
    schema: null, 
    method: 'POST',
    body: body
  }
})

export const updateTest = (body) => (dispatch, getState) => {
  return dispatch(fetchUpdateTest(body))
}

export const DELETE_PARTNER_TEST_REQUEST = 'DELETE_PARTNER_TEST_REQUEST'
export const DELETE_PARTNER_TEST_SUCCESS = 'DELETE_PARTNER_TEST_SUCCESS'
export const DELETE_PARTNER_TEST_FAILURE = 'DELETE_PARTNER_TEST_FAILURE'

const fetchDeleteTest = (testId) => ({
  [CALL_API]: {
    types: [DELETE_PARTNER_TEST_REQUEST, DELETE_PARTNER_TEST_SUCCESS, DELETE_PARTNER_TEST_FAILURE],
    endpoint: `secure/partners/delete-test/${testId}`,
    schema: null, 
  }
})

export const deleteTest = (testId) => (dispatch, getState) => {
  return dispatch(fetchDeleteTest(testId))
}

export const GET_PARTNER_SHOP_DETAILS_REQUEST = 'GET_PARTNER_SHOP_DETAILS_REQUEST'
export const GET_PARTNER_SHOP_DETAILS_SUCCESS = 'GET_PARTNER_SHOP_DETAILS_SUCCESS'
export const GET_PARTNER_SHOP_DETAILS_FAILURE = 'GET_PARTNER_SHOP_DETAILS_FAILURE'

const fetchPartnerShopDetails = (body) => ({
  [CALL_API]: {
    types: [GET_PARTNER_SHOP_DETAILS_REQUEST, GET_PARTNER_SHOP_DETAILS_SUCCESS, GET_PARTNER_SHOP_DETAILS_FAILURE],
    endpoint: `/partners/get-partnerShop-details`,
    schema: Schemas.PARTNER_SHOP, 
    method: 'POST',
    body: body
  }
})

export const loadPartnerShopDetails = (body) => (dispatch, getState) => {
  return dispatch(fetchPartnerShopDetails(body))
}

export const ADD_PARTNER_TEST_REQUEST = 'ADD_PARTNER_TEST_REQUEST'
export const ADD_PARTNER_TEST_SUCCESS = 'ADD_PARTNER_TEST_SUCCESS'
export const ADD_PARTNER_TEST_FAILURE = 'ADD_PARTNER_TEST_FAILURE'

const fetchAddTest = (testId) => ({
  [CALL_API]: {
    types: [ADD_PARTNER_TEST_REQUEST, ADD_PARTNER_TEST_SUCCESS, ADD_PARTNER_TEST_FAILURE],
    endpoint: `secure/partners/add-test/${testId}`,
    schema: null, 
  }
})

export const addTest = (testId) => (dispatch, getState) => {
  return dispatch(fetchAddTest(testId))
}

export const GET_PARTNER_TEST_RESULTS_REQUEST = 'GET_PARTNER_TEST_RESULTS_REQUEST'
export const GET_PARTNER_TEST_RESULTS_SUCCESS = 'GET_PARTNER_TEST_RESULTS_SUCCESS'
export const GET_PARTNER_TEST_RESULTS_FAILURE = 'GET_PARTNER_TEST_RESULTS_FAILURE'

const fetchPartnerResults = (partnerId) => ({
  partnerId,
  [CALL_API]: {
    types: [GET_PARTNER_TEST_RESULTS_REQUEST, GET_PARTNER_TEST_RESULTS_SUCCESS, GET_PARTNER_TEST_RESULTS_FAILURE],
    endpoint: `secure/partners/get-partner-results/${partnerId}`,
    schema: Schemas.PARTNER_RESULTS, 
  }
})

export const getPartnerResults = (partnerId) => (dispatch, getState) => {
  return dispatch(fetchPartnerResults(partnerId))
}


export const GET_PARTNER_USERS_REQUEST = 'GET_PARTNER_USERS_REQUEST'
export const GET_PARTNER_USERS_SUCCESS = 'GET_PARTNER_USERS_SUCCESS'
export const GET_PARTNER_USERS_FAILURE = 'GET_PARTNER_USERS_FAILURE'

const fetchPartnerUsers = (partnerId) => ({
  partnerId,
  [CALL_API]: {
    types: [GET_PARTNER_USERS_REQUEST, GET_PARTNER_USERS_SUCCESS, GET_PARTNER_USERS_FAILURE],
    endpoint: `secure/partners/get-partner-users`,
    schema: Schemas.PARTNER_USERS_ARRAY, 
  }
})

export const getPartnerUsers = (partnerId) => (dispatch, getState) => {
  const partnerUsersArray = getState().entities.partnerUsersArray;
  if (Object.keys(partnerUsersArray).length > 0) {
    Object.keys(partnerUsersArray).map(function(key) {
      delete partnerUsersArray[key];
    })  
  } 
  return dispatch(fetchPartnerUsers(partnerId))
}

export const UPDATE_PARTNER_USERS_REQUEST = 'UPDATE_PARTNER_USERS_REQUEST'
export const UPDATE_PARTNER_USERS_SUCCESS = 'UPDATE_PARTNER_USERS_SUCCESS'
export const UPDATE_PARTNER_USERS_FAILURE = 'UPDATE_PARTNER_USERS_FAILURE'

const fetchUpdatePartnerUser = (body) => ({
  body,
  [CALL_API]: {
    types: [UPDATE_PARTNER_USERS_REQUEST, UPDATE_PARTNER_USERS_SUCCESS, UPDATE_PARTNER_USERS_FAILURE],
    endpoint: `secure/partners/update-partner-user`,
    schema: Schemas.PARTNER_USERS_ARRAY, 
    method: 'POST',
    body: body
  }
})

export const updatePartnerUser = (body) => (dispatch, getState) => {
  return dispatch(fetchUpdatePartnerUser(body))
}

export const LOAD_PARTNER_TAGS_REQUEST = 'LOAD_PARTNER_TAGS_REQUEST'
export const LOAD_PARTNER_TAGS_SUCCESS = 'LOAD_PARTNER_TAGS_SUCCESS'
export const LOAD_PARTNER_TAGS_FAILURE = 'LOAD_PARTNER_TAGS_FAILURE'

const fetchLoadPartnerTag = (body) => ({
  body,
  [CALL_API]: {
    types: [LOAD_PARTNER_TAGS_REQUEST, LOAD_PARTNER_TAGS_SUCCESS, LOAD_PARTNER_TAGS_FAILURE],
    endpoint: `secure/partners/load-partner-tags`,
    schema: Schemas.PARTNER_TAGS,
    method: 'POST',
    body: body
  }
})

export const loadPartnerTags = (body) => (dispatch, getState) => {
  return dispatch(fetchLoadPartnerTag(body))
}

export const ADD_PARTNER_TAG_REQUEST = 'ADD_PARTNER_TAG_REQUEST'
export const ADD_PARTNER_TAG_SUCCESS = 'ADD_PARTNER_TAG_SUCCESS'
export const ADD_PARTNER_TAG_FAILURE = 'ADD_PARTNER_TAG_FAILURE'

const fetchAddPartnerTag = (body) => ({
  body,
  [CALL_API]: {
    types: [ADD_PARTNER_TAG_REQUEST, ADD_PARTNER_TAG_SUCCESS, ADD_PARTNER_TAG_FAILURE],
    endpoint: `secure/partners/add-partner-tag`,
    schema: Schemas.PARTNER_TAGS, 
    method: 'POST',
    body: body
  }
})

export const addPartnerTag = (body) => (dispatch, getState) => {
  return dispatch(fetchAddPartnerTag(body))
}

export const LOAD_ORDER_STATUS_REQUEST = 'LOAD_ORDER_STATUS_REQUEST'
export const LOAD_ORDER_STATUS_SUCCESS = 'LOAD_ORDER_STATUS_SUCCESS'
export const LOAD_ORDER_STATUS_FAILURE = 'LOAD_ORDER_STATUS_FAILURE'

const fetchLoadOrderStatus = () => ({
  [CALL_API]: {
    types: [LOAD_ORDER_STATUS_REQUEST, LOAD_ORDER_STATUS_SUCCESS, LOAD_ORDER_STATUS_FAILURE],
    endpoint: `secure/partners/load-order-status`,
    schema: null, 
  }
})

export const loadOrderStatus = () => (dispatch, getState) => {
  return dispatch(fetchLoadOrderStatus())
}

export const DELETE_PARTNER_TAG_REQUEST = 'DELETE_PARTNER_TAG_REQUEST'
export const DELETE_PARTNER_TAG_SUCCESS = 'DELETE_PARTNER_TAG_SUCCESS'
export const DELETE_PARTNER_TAG_FAILURE = 'DELETE_PARTNER_TAG_FAILURE'

const fetchDeletePartnerTag = (id) => ({
  id,
  [CALL_API]: {
    types: [DELETE_PARTNER_TAG_REQUEST, DELETE_PARTNER_TAG_SUCCESS, DELETE_PARTNER_TAG_FAILURE],
    endpoint: `secure/partners/delete-partner-tag/${id}`,
    schema: null,
  }
})

export const deletePartnerTag = (id) => (dispatch, getState) => {
  return dispatch(fetchDeletePartnerTag(id))
}

export const EDIT_PARTNER_TAG_REQUEST = 'EDIT_PARTNER_TAG_REQUEST'
export const EDIT_PARTNER_TAG_SUCCESS = 'EDIT_PARTNER_TAG_SUCCESS'
export const EDIT_PARTNER_TAG_FAILURE = 'EDIT_PARTNER_TAG_FAILURE'

const fetchEditPartnerTag = (body) => ({
  body,
  [CALL_API]: {
    types: [EDIT_PARTNER_TAG_REQUEST, EDIT_PARTNER_TAG_SUCCESS, EDIT_PARTNER_TAG_FAILURE],
    endpoint: `secure/partners/edit-partner-tag`,
    schema: Schemas.PARTNER_TAGS, 
    method: 'POST',
    body: body
  }
})

export const editPartnerTag = (body) => (dispatch, getState) => {
  return dispatch(fetchEditPartnerTag(body))
}

export const TESTS_REQUEST = 'TESTS_REQUEST'
export const TESTS_SUCCESS = 'TESTS_SUCCESS'
export const TESTS_FAILURE = 'TESTS_FAILURE'

const fetchCompletedTests = (userId) => ({
  userId,
  [CALL_API]: {
    types: [ TESTS_REQUEST, TESTS_SUCCESS, TESTS_FAILURE ],
    endpoint: `secure/partners/all-results/${userId}`,
    schema: Schemas.TESTS_RESULTS
  }
})

export const loadCompletedTests = (userId) => (dispatch, getState) => {
  const tests = getState().entities.tests;

  if (Object.keys(tests).length > 0) {
    Object.keys(tests).map(function(key) {

      delete tests[key];

    })  
  } 
  return dispatch(fetchCompletedTests(userId))
}

export const ALL_BIOMARKERS_FROM_ORDER_REQUEST = 'ALL_BIOMARKERS_FROM_ORDER_REQUEST'
export const ALL_BIOMARKERS_FROM_ORDER_SUCCESS = 'ALL_BIOMARKERS_FROM_ORDER_SUCCESS'
export const ALL_BIOMARKERS_FROM_ORDER_FAILURE = 'ALL_BIOMARKERS_FROM_ORDER_FAILURE'

const fetchAllResultsFromOrder = (orderId, userId) => ({
  orderId, userId,
  [CALL_API]: {
    types: [ ALL_BIOMARKERS_FROM_ORDER_REQUEST, ALL_BIOMARKERS_FROM_ORDER_SUCCESS, ALL_BIOMARKERS_FROM_ORDER_FAILURE ],
    endpoint: `/secure/partners/all-order-results/${orderId}/${userId}`,
    schema: Schemas.ALL_BIOMARKERS_FROM_ORDER
  }
})

export const loadAllResultsFromOrder = (orderId, userId) => (dispatch, getState) => {
  var orderBiomarkers = getState().entities.orderBiomarkers;
  if (Object.keys(orderBiomarkers).length > 0) {
    Object.keys(orderBiomarkers).map(function(key) {
      delete orderBiomarkers[key];
    })  
  }
  return dispatch(fetchAllResultsFromOrder(orderId, userId))
}


export const GET_HP_COMMENT_REQUEST = 'GET_HP_COMMENT_REQUEST'
export const GET_HP_COMMENT_SUCCESS = 'GET_HP_COMMENT_SUCCESS'
export const GET_HP_COMMENT_FAILURE = 'GET_HP_COMMENT_FAILURE'

const fetchHpComment = (orderId, userId) => ({
  orderId,userId,
  [CALL_API]: {
    types: [ GET_HP_COMMENT_REQUEST, GET_HP_COMMENT_SUCCESS, GET_HP_COMMENT_FAILURE ],
    endpoint: `secure/partners/hp-comment/${orderId}/${userId}`,
    schema: Schemas.HP_COMMENT
  }
})

export const loadHpComment = (orderId, userId) => (dispatch, getState) => {
  const hpComment = getState().entities.hpComment;
 
  if (Object.keys(hpComment).length > 0) {
    Object.keys(hpComment).map(function(key) {

      delete hpComment[key];

    })  
  } 
  return dispatch(fetchHpComment(orderId, userId))
}


export const UPDATE_HIDE_COMMENT_REQUEST = 'UPDATE_HIDE_COMMENT_REQUEST'
export const UPDATE_HIDE_COMMENT_SUCCESS = 'UPDATE_HIDE_COMMENT_SUCCESS'
export const UPDATE_HIDE_COMMENT_FAILURE = 'UPDATE_HIDE_COMMENT_FAILURE'

const fetchHideComment = (body) => ({
  body,
  [CALL_API]: {
    types: [ UPDATE_HIDE_COMMENT_REQUEST, UPDATE_HIDE_COMMENT_SUCCESS, UPDATE_HIDE_COMMENT_FAILURE ],
    endpoint: `secure/partners/hide-comment`,
    schema: null,
    method: 'POST',
    body: body
  }
})

export const hideComment = (body) => (dispatch, getState) => {
  return dispatch(fetchHideComment(body))
}

export const UPDATE_PARTNERSHOP_SESSION_REQUEST = 'UPDATE_PARTNERSHOP_SESSION_REQUEST'
export const UPDATE_PARTNERSHOP_SESSION_SUCCESS = 'UPDATE_PARTNERSHOP_SESSION_SUCCESS'
export const UPDATE_PARTNERSHOP_SESSION_FAILURE = 'UPDATE_PARTNERSHOP_SESSION_FAILURE'

const fetchUpdatePartnershopSession = (body) => ({
  body,
  [CALL_API]: {
    types: [ UPDATE_PARTNERSHOP_SESSION_REQUEST, UPDATE_PARTNERSHOP_SESSION_SUCCESS, UPDATE_PARTNERSHOP_SESSION_FAILURE ],
    endpoint: `partners/update-partnershop-sessions`,
    schema: null,
    method: 'POST',
    body: body
  }
})

export const updatePartnershopSession = (body) => (dispatch, getState) => {
  return dispatch(fetchUpdatePartnershopSession(body))
}

export const LOAD_PARTNERSHOP_SESSION_REQUEST = 'LOAD_PARTNERSHOP_SESSION_REQUEST'
export const LOAD_PARTNERSHOP_SESSION_SUCCESS = 'LOAD_PARTNERSHOP_SESSION_SUCCESS'
export const LOAD_PARTNERSHOP_SESSION_FAILURE = 'LOAD_PARTNERSHOP_SESSION_FAILURE'

const fetchLoadPartnershopSession = (body) => ({
  body,
  [CALL_API]: {
    types: [ LOAD_PARTNERSHOP_SESSION_REQUEST, LOAD_PARTNERSHOP_SESSION_SUCCESS, LOAD_PARTNERSHOP_SESSION_FAILURE ],
    endpoint: `secure/partners/load-partnershop-sessions`,
    schema: Schemas.PARTNER_SESSIONS,
  }
})

export const loadPartnershopSession = (body) => (dispatch, getState) => {
  return dispatch(fetchLoadPartnershopSession(body))
}

export const CURRENT_BIOMARKER_REQUEST = 'CURRENT_BIOMARKER_REQUEST'
export const CURRENT_BIOMARKER_SUCCESS = 'CURRENT_BIOMARKER_SUCCESS'
export const CURRENT_BIOMARKER_FAILURE = 'CURRENT_BIOMARKER_FAILURE'

const fetchCurrentMarker = (userId, biomarkerResultId) => ({
  userId, biomarkerResultId,
  [CALL_API]: {
    types: [ CURRENT_BIOMARKER_REQUEST, CURRENT_BIOMARKER_SUCCESS, CURRENT_BIOMARKER_FAILURE ],
    endpoint: `/secure/partners/current-marker/${userId}/${biomarkerResultId}`,
    schema: Schemas.CURRENT_BIOMARKER
  }
})

export const loadCurrentMarker = (userId, biomarkerResultId) => (dispatch, getState) => {
  const currentMarker = getState().entities.currentMarker;

  if (Object.keys(currentMarker).length > 0) {
    Object.keys(currentMarker).map(function(key) {

      delete currentMarker[key];

    })  
  }

  return dispatch(fetchCurrentMarker(userId, biomarkerResultId))
}

export const CURRENT_BIOMARKER_HISTORY_REQUEST = 'CURRENT_BIOMARKER_HISTORY_REQUEST'
export const CURRENT_BIOMARKER_HISTORY_SUCCESS = 'CURRENT_BIOMARKER_HISTORY_SUCCESS'
export const CURRENT_BIOMARKER_HISTORY_FAILURE = 'CURRENT_BIOMARKER_HISTORY_FAILURE'

const fetchCurrentMarkerHistory = (userId, biomarkerId) => ({
  userId, biomarkerId,
  [CALL_API]: {
    types: [ CURRENT_BIOMARKER_HISTORY_REQUEST, CURRENT_BIOMARKER_HISTORY_SUCCESS, CURRENT_BIOMARKER_HISTORY_FAILURE ],
    endpoint: `/secure/partners/current-marker-history/${userId}/${biomarkerId}`,
    schema: Schemas.CURRENT_BIOMARKER_HISTORY
  }
})

export const loadCurrentMarkerHistory = (userId, biomarkerId) => (dispatch, getState) => {
  const currentMarkerHistory = getState().entities.currentHistoryMarker;

  if (Object.keys(currentMarkerHistory).length > 0) {
    Object.keys(currentMarkerHistory).map(function(key) {

      delete currentMarkerHistory[key];

    })  
  }

  return dispatch(fetchCurrentMarkerHistory(userId, biomarkerId))
}


export const BIOMARKER_AVERAGE_REQUEST = 'BIOMARKER_AVERAGE_REQUEST'
export const BIOMARKER_AVERAGE_SUCCESS = 'BIOMARKER_AVERAGE_SUCCESS'
export const BIOMARKER_AVERAGE_FAILURE = 'BIOMARKER_AVERAGE_FAILURE'

const fetchMarkerAverage = (userId, biomarkerResultId) => ({
  userId, biomarkerResultId,
  [CALL_API]: {
    types: [ BIOMARKER_AVERAGE_REQUEST, BIOMARKER_AVERAGE_SUCCESS, BIOMARKER_AVERAGE_FAILURE ],
    endpoint: `/secure/partners/marker-average/${userId}/${biomarkerResultId}`,
    schema: Schemas.BIOMARKER_AVERAGE
  }
})

export const loadMarkerAverage = (userId, biomarkerResultId) => (dispatch, getState) => {
  const markerAverage = getState().entities.markerAverage;
  if (Object.keys(markerAverage).length > 0) {
    Object.keys(markerAverage).map(function(key) {

      delete markerAverage[key];

    })  
  }

  return dispatch(fetchMarkerAverage(userId, biomarkerResultId))
}

export const GET_LAST_ORDER_DATE_REQUEST = 'GET_LAST_ORDER_DATE_REQUEST'
export const GET_LAST_ORDER_DATE_SUCCESS = 'GET_LAST_ORDER_DATE_SUCCESS'
export const GET_LAST_ORDER_DATE_FAILURE = 'GET_LAST_ORDER_DATE_FAILURE'

const fetchLastOrderDate = (userId) => ({
  userId,
  [CALL_API]: {
    types: [ GET_LAST_ORDER_DATE_REQUEST, GET_LAST_ORDER_DATE_SUCCESS, GET_LAST_ORDER_DATE_FAILURE ],
    endpoint: `secure/partners/last-test/${userId}`,
    schema: Schemas.LAST_ORDER
  }
})

export const loadLastOrderDate = (userId) => (dispatch, getState) => {
  return dispatch(fetchLastOrderDate(userId))
}

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST'
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS'
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE'

const fetchDeleteUser = (userId) => ({
  userId,
  [CALL_API]: {
    types: [ DELETE_USER_REQUEST, DELETE_USER_SUCCESS, DELETE_USER_FAILURE ],
    endpoint: `secure/partners/delete-user/${userId}`,
    schema: null
  }
})

export const deleteUser = (userId) => (dispatch, getState) => {
  return dispatch(fetchDeleteUser(userId))
}


export const FEMALE_HORMONES_RESULTS_REQUEST = 'FEMALE_HORMONES_RESULTS_REQUEST'
export const FEMALE_HORMONES_RESULTS_SUCCESS = 'FEMALE_HORMONES_RESULTS_SUCCESS'
export const FEMALE_HORMONES_RESULTS_FAILURE = 'FEMALE_HORMONES_RESULTS_FAILURE'

const fetchFemaleHormonesResult = (userId, orderId) => ({
  userId, orderId,
  [CALL_API]: {
    types: [ FEMALE_HORMONES_RESULTS_REQUEST, FEMALE_HORMONES_RESULTS_SUCCESS, FEMALE_HORMONES_RESULTS_FAILURE ],
    endpoint: `secure/partners/get-hormone-results/${userId}/${orderId}`,
    schema: Schemas.FEMALE_HORMONES_RESULTS
  }
})

export const loadFemaleHormonesResult = (userId, orderId) => (dispatch, getState) => {
  var femaleHormoneResults = getState().entities.femaleHormoneResults;
  if (Object.keys(femaleHormoneResults).length > 0) {
    Object.keys(femaleHormoneResults).map(function(key) {

      delete femaleHormoneResults[key];

    })  
  }
  return dispatch(fetchFemaleHormonesResult(userId, orderId))
}

export const GET_TEST_PDF_REQUEST = 'GET_TEST_PDF_REQUEST'
export const GET_TEST_PDF_SUCCESS = 'GET_TEST_PDF_SUCCESS'
export const GET_TEST_PDF_FAILURE = 'GET_TEST_PDF_FAILURE'

const fetchFHMExport = (body) => ({
  [CALL_API]: {
    types: [GET_TEST_PDF_REQUEST, GET_TEST_PDF_SUCCESS, GET_TEST_PDF_FAILURE],
    endpoint: `secure/partners/get-fhm-pdf`,
    schema: Schemas.PDF_RESULTS, 
    method: 'POST', 
    body: body
  }
})

export const getFHMExport = (body) => (dispatch, getState) => {
  return dispatch(fetchFHMExport(body))
}

const fetchGetPDF = (userId, orderId, resultType, dataType) => ({
  userId, orderId,
  [CALL_API]: {
    types: [GET_TEST_PDF_REQUEST, GET_TEST_PDF_SUCCESS, GET_TEST_PDF_FAILURE],
    endpoint: `secure/partners/get-pdf/${userId}/${resultType}/${dataType}/${orderId}`,
    schema: Schemas.PDF_RESULTS, 
  }
})

export const getPartnerPDF = (userId, orderId, resultType, dataType) => (dispatch, getState) => {
  var pdfs = getState().entities.pdfs;
  if (Object.keys(pdfs).length > 0) {
    Object.keys(pdfs).map(function(key) {

      delete pdfs[key];

    }) 
  } 
  return dispatch(fetchGetPDF(userId, orderId, resultType, dataType))
}

export const GET_RECIEPT_REQUEST = 'GET_RECIEPT_REQUEST'
export const GET_RECIEPT_SUCCESS = 'GET_RECIEPT_SUCCESS'
export const GET_RECIEPT_FAILURE = 'GET_RECIEPT_FAILURE'

const fetchGetReceipt = (userId, orderId) => ({
  orderId,
  [CALL_API]: {
    types: [GET_RECIEPT_REQUEST, GET_RECIEPT_SUCCESS, GET_RECIEPT_FAILURE],
    endpoint: `secure/partners/get-receipt/${userId}/${orderId}`,
    schema: Schemas.PDF_RESULTS, 
  }
})


export const getReceipt = (userId, orderId) => (dispatch, getState) => {
  return dispatch(fetchGetReceipt(userId, orderId))
}

export const ADD_PARTNER_USER_REQUEST = 'ADD_PARTNER_USER_REQUEST'
export const ADD_PARTNER_USER_SUCCESS = 'ADD_PARTNER_USER_SUCCESS'
export const ADD_PARTNER_USER_FAILURE = 'ADD_PARTNER_USER_FAILURE'

const fetchAddUser = (body) => ({
  [CALL_API]: {
    types: [ ADD_PARTNER_USER_REQUEST, ADD_PARTNER_USER_SUCCESS, ADD_PARTNER_USER_FAILURE ],
    endpoint: 'secure/partners/add-user',
    schema: null,
    method: 'POST', 
    body: body
  }
})

export const addUser  = (body) => (dispatch, getState) => {
  return dispatch(fetchAddUser(body))
};

export const GET_SUBSCRIPTIONS_REQUEST = 'GET_SUBSCRIPTIONS_REQUEST'
export const GET_SUBSCRIPTIONS_SUCCESS = 'GET_SUBSCRIPTIONS_SUCCESS'
export const GET_SUBSCRIPTIONS_FAILURE = 'GET_SUBSCRIPTIONS_FAILURE'

const fetchSubscriptions = (userId) => ({
  userId,
  [CALL_API]: {
    types: [ GET_SUBSCRIPTIONS_REQUEST, GET_SUBSCRIPTIONS_SUCCESS, GET_SUBSCRIPTIONS_FAILURE ],
    endpoint: `secure/partners/subscriptions/${userId}`,
    schema: Schemas.SUBSCRIPTIONS
  }
})

export const loadSubscriptions = (userId) => (dispatch, getState) => {
  return dispatch(fetchSubscriptions(userId))
}

export const REQUEST_USER_ACCESS_REQUEST = 'REQUEST_USER_ACCESS_REQUEST'
export const REQUEST_USER_ACCESS_SUCCESS = 'REQUEST_USER_ACCESS_SUCCESS'
export const REQUEST_USER_ACCESS_FAILURE = 'REQUEST_USER_ACCESS_FAILURE'

const fetchUserRequestAccess = (body) => ({
  body,
  [CALL_API]: {
    types: [ REQUEST_USER_ACCESS_REQUEST, REQUEST_USER_ACCESS_SUCCESS, REQUEST_USER_ACCESS_FAILURE ],
    endpoint: `secure/partners/request-user-access`,
    schema: null,
    body: body,
    method: 'POST', 
  }
})

export const requestUserAccess = (body) => (dispatch, getState) => {
  return dispatch(fetchUserRequestAccess(body))
}

export const ACCEPT_TERMS_AND_CONDITIONS_REQUEST = 'ACCEPT_TERMS_AND_CONDITIONS_REQUEST'
export const ACCEPT_TERMS_AND_CONDITIONS_SUCCESS = 'ACCEPT_TERMS_AND_CONDITIONS_SUCCESS'
export const ACCEPT_TERMS_AND_CONDITIONS_FAILURE = 'ACCEPT_TERMS_AND_CONDITIONS_FAILURE'

const fetchAcceptTermsAndConditions = () => ({
  [CALL_API]: {
    types: [ ACCEPT_TERMS_AND_CONDITIONS_REQUEST, ACCEPT_TERMS_AND_CONDITIONS_SUCCESS, ACCEPT_TERMS_AND_CONDITIONS_FAILURE ],
    endpoint: `secure/partners/terms-and-conditions`,
    schema: null,
  }
})

export const acceptTermsAndConditions = () => (dispatch, getState) => {
  return dispatch(fetchAcceptTermsAndConditions())
}

export const RELEASE_RESULT_REQUEST = 'RELEASE_RESULT_REQUEST'
export const RELEASE_RESULT_SUCCESS = 'RELEASE_RESULT_SUCCESS'
export const RELEASE_RESULT_FAILURE = 'RELEASE_RESULT_FAILURE'

const fetchReleaseResult = (body) => ({
  body,
  [CALL_API]: {
    types: [ RELEASE_RESULT_REQUEST, RELEASE_RESULT_SUCCESS, RELEASE_RESULT_FAILURE ],
    endpoint: `secure/partners/release-results`,
    schema: null,
    body: body,
    method: 'POST', 
  }
})

export const releaseResult = (body) => (dispatch, getState) => {
  return dispatch(fetchReleaseResult(body))
}

export const UPDATE_ORDER_REQUEST = 'UPDATE_ORDER_REQUEST'
export const UPDATE_ORDER_SUCCESS = 'UPDATE_ORDER_SUCCESS'
export const UPDATE_ORDER_FAILURE = 'UPDATE_ORDER_FAILURE'

const fetchCancelOrder = (body) => ({
  [CALL_API]: {
    types: [ UPDATE_ORDER_REQUEST, UPDATE_ORDER_SUCCESS, UPDATE_ORDER_FAILURE ],
    endpoint: 'secure/partners/cancel',
    schema: null,
    method: 'POST', 
    body: body
  }
})

export const updateCancelOrder = (body) => (dispatch, getState) => {
  return dispatch(fetchCancelOrder(body))
};

export const ALL_BIOMARKER_HISTORY_REQUEST = 'ALL_BIOMARKER_HISTORY_REQUEST'
export const ALL_BIOMARKER_HISTORY_SUCCESS = 'ALL_BIOMARKER_HISTORY_SUCCESS'
export const ALL_BIOMARKER_HISTORY_FAILURE = 'ALL_BIOMARKER_HISTORY_FAILURE'

const fetchMarkerHistory = (userId) => ({
  userId,
  [CALL_API]: {
    types: [ ALL_BIOMARKER_HISTORY_REQUEST, ALL_BIOMARKER_HISTORY_SUCCESS, ALL_BIOMARKER_HISTORY_FAILURE ],
    endpoint: `/secure/partners/get-marker-history/${userId}`,
    schema: Schemas.BIOMARKER_HISTORY
  }
})

export const partnerLoadMarkerHistory = (userId) => (dispatch, getState) => {
  var markerHistory = getState().entities.markerHistory;
  if (Object.keys(markerHistory).length > 0) {
    Object.keys(markerHistory).map(function(key) {

      delete markerHistory[key];

    })  
  }

  return dispatch(fetchMarkerHistory(userId))
}

export const LOAD_LATEST_BIOMARKERS_REQUEST = 'LOAD_LATEST_BIOMARKERS_REQUEST'
export const LOAD_LATEST_BIOMARKERS_SUCCESS = 'LOAD_LATEST_BIOMARKERS_SUCCESS'
export const LOAD_LATEST_BIOMARKERS_FAILURE = 'LOAD_LATEST_BIOMARKERS_FAILURE'

const fetchLoadAllLatestBiomarkers = (userId) => ({
  userId,
  [CALL_API]: {
    types: [ LOAD_LATEST_BIOMARKERS_REQUEST, LOAD_LATEST_BIOMARKERS_SUCCESS, LOAD_LATEST_BIOMARKERS_FAILURE ],
    endpoint: `secure/partners/get-latest-markers/${userId}`,
    schema: Schemas.ALL_LATEST_BIOMARKERS
  }
})

export const partnerLoadAllLatestBiomarkers = (userId) => (dispatch, getState) => {
  var allLatestBiomarkers = getState().entities.allLatestBiomarkers;
  if (Object.keys(allLatestBiomarkers).length > 0) {
    Object.keys(allLatestBiomarkers).map(function(key) {

      delete allLatestBiomarkers[key];

    })  
  }

  return dispatch(fetchLoadAllLatestBiomarkers(userId))
}

export const LOAD_FAILED_BIOMARKERS_REQUEST = 'LOAD_FAILED_BIOMARKERS_REQUEST'
export const LOAD_FAILED_BIOMARKERS_SUCCESS = 'LOAD_FAILED_BIOMARKERS_SUCCESS'
export const LOAD_FAILED_BIOMARKERS_FAILURE = 'LOAD_FAILED_BIOMARKERS_FAILURE'

const fetchLoadFailedBiomarkers = (orderId, userId,) => ({
  userId,
  [CALL_API]: {
    types: [ LOAD_FAILED_BIOMARKERS_REQUEST, LOAD_FAILED_BIOMARKERS_SUCCESS, LOAD_FAILED_BIOMARKERS_FAILURE ],
    endpoint: `secure/partners/get-biomarkers-failed/${orderId}/${userId}`,
    schema: null
  }
})

export const partnerLoadFailedBiomarkers = (orderId, userId) => (dispatch, getState) => {
  return dispatch(fetchLoadFailedBiomarkers(orderId, userId))
}

export const SET_NEW_UPDATES_REQUEST = 'SET_NEW_UPDATES_REQUEST'
export const SET_NEW_UPDATES_SUCCESS = 'SET_NEW_UPDATES_SUCCESS'
export const SET_NEW_UPDATES_FAILURE = 'SET_NEW_UPDATES_FAILURE'

const fetchSetNewUpdates = (body) => ({
  [CALL_API]: {
    types: [ SET_NEW_UPDATES_REQUEST, SET_NEW_UPDATES_SUCCESS, SET_NEW_UPDATES_FAILURE ],
    endpoint: `secure/partners/set-new-updates`,
    schema: null,
    method: 'POST', 
    body: body
  }
})

export const setNewUpdates = (body) => (dispatch, getState) => {
  return dispatch(fetchSetNewUpdates(body))
}


export const CREATE_NEW_USER_REQUEST = 'CREATE_NEW_USER_REQUEST'
export const CREATE_NEW_USER_SUCCESS = 'CREATE_NEW_USER_SUCCESS'
export const CREATE_NEW_USER_FAILURE = 'CREATE_NEW_USER_FAILURE'

const fetchCreateNewUser = (body) => ({
  [CALL_API]: {
    types: [ CREATE_NEW_USER_REQUEST, CREATE_NEW_USER_SUCCESS, CREATE_NEW_USER_FAILURE ],
    endpoint: `secure/partners/create-new-user`,
    schema: null,
    method: 'POST', 
    body: body
  }
})

export const createNewUser = (body) => (dispatch, getState) => {
  return dispatch(fetchCreateNewUser(body))
}


export const PARTNER_SIGNUP_CODE_REQUEST = 'PARTNER_SIGNUP_CODE_REQUEST'
export const PARTNER_SIGNUP_CODE_SUCCESS = 'PARTNER_SIGNUP_CODE_SUCCESS'
export const PARTNER_SIGNUP_CODE_FAILURE = 'PARTNER_SIGNUP_CODE_FAILURE'

const fetchPartnerSignupCode = () => ({
  [CALL_API]: {
    types: [PARTNER_SIGNUP_CODE_REQUEST, PARTNER_SIGNUP_CODE_SUCCESS, PARTNER_SIGNUP_CODE_FAILURE ],
    endpoint: `/partners/generate-partner-signup`,
    schema: null,
  }
})

export const getPartnerSignupCode = () => (dispatch, getState) => {
  return dispatch(fetchPartnerSignupCode())
};





export const LOAD_PARTNER_STATS_REQUEST = 'LOAD_PARTNER_STATS_REQUEST'
export const LOAD_PARTNER_STATS_SUCCESS = 'LOAD_PARTNER_STATS_SUCCESS'
export const LOAD_PARTNER_STATS_FAILURE = 'LOAD_PARTNER_STATS_FAILURE'

const fetchPartnerStats = () => ({
  [CALL_API]: {
    types: [ LOAD_PARTNER_STATS_REQUEST, LOAD_PARTNER_STATS_SUCCESS, LOAD_PARTNER_STATS_FAILURE ],
    endpoint: `secure/partners/get-stats`,
    schema: Schemas.PARTNER_STATS
  }
})

export const getPartnerStats = () => (dispatch, getState) => {
  return dispatch(fetchPartnerStats())
}






export const PARTNER_ORDERS_REQUESTV2 = 'PARTNER_ORDERS_REQUESTV2'
export const PARTNER_ORDERS_SUCCESSV2 = 'PARTNER_ORDERS_SUCCESSV2'
export const PARTNER_ORDERS_FAILUREV2 = 'PARTNER_ORDERS_FAILUREV2'

const fetchPartnerOrderDetailsV2 = () => ({
  [CALL_API]: {
    types: [ PARTNER_ORDERS_REQUEST, PARTNER_ORDERS_SUCCESS, PARTNER_ORDERS_FAILURE ],
    endpoint: `secure/v2/partners/get-partner-orders`,
    schema: Schemas.GET_PARTNER_ORDERSV2,
  }
})

export const getPartnerOrderDetailsV2 = () => (dispatch, getState) => {
  return dispatch(fetchPartnerOrderDetailsV2())
};


export const UPDATE_PARTNER_LOGS_REQUEST = 'UPDATE_PARTNER_LOGS_REQUEST'
export const UPDATE_PARTNER_LOGS_SUCCESS = 'UPDATE_PARTNER_LOGS_SUCCESS'
export const UPDATE_PARTNER_LOGS_FAILURE = 'UPDATE_PARTNER_LOGS_FAILURE'

const fetchUpdateLogs = (payload) => ({
  payload,
  [CALL_API]: {
    endpoint: `secure/partners/update-logs`,
    method: 'POST',
    types: [ UPDATE_PARTNER_LOGS_REQUEST, UPDATE_PARTNER_LOGS_SUCCESS, UPDATE_PARTNER_LOGS_FAILURE ],
    body: {
      partnerUserId: payload.partnerUserId,
      orderId: payload.orderId,
      message: payload.message,
      logType: payload.logType,
    }
  }
})

export const updateLogs = (payload) => (dispatch, getState) => {
  const partnerUserId = getState().user.userId || 0

  return dispatch(fetchUpdateLogs({...payload, partnerUserId}))
};
 