import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'

export default class MobileMenu extends Component {
  render() {
    return (
      <div className="row text-center align-items-center justify-content-center g-0 text-uppercase px-2">
        <div className="col">
          <NavLink exact activeClassName="active" className="nav-link px-0 mobile-nav d-flex justify-content-center align-content-center flex-column" to="/">
            <i className="fas fa-chart-bar pb-2"></i>
            Dashboard
          </NavLink>
        </div>
        <div className="col position-relative">
          <NavLink exact activeClassName="active" className="nav-link px-0 mobile-nav d-flex justify-content-center align-content-center flex-column" to="/partner-orders">
            <i className="fal fa-receipt pb-2"></i>
            Orders
          </NavLink>
          {/* <span className="notif-num"></span> */}
        </div>
        <div className="col position-relative">
          <NavLink exact to="/partner-results" isActive={(match, location) => location.pathname.split("/").includes('partner-results')} className="nav-link px-0 mobile-nav d-flex justify-content-center align-content-center flex-column">
            <i className="far fa-tint pb-2"></i>
            Results
          </NavLink>
          {/* <span className="notif-num"></span> */}
        </div>
        <div className="col">
          <NavLink exact to="/partner-clients" isActive={(match, location) => location.pathname.split("/").includes('partner-clients')} className="nav-link mobile-nav d-flex justify-content-center align-content-center flex-column px-0">
            <i className="far fa-users pb-2"></i>
            Clients
          </NavLink>
        </div>
        {/*
        <div className="col">
          <div className="mobile-nav d-flex justify-content-center align-content-center flex-column">
            <i className="fal fa-life-ring pb-2"></i>
            <p className="px-0">Help Guides</p>
          </div>
        </div>
        */}
      </div>
    )
  }
}
