const defaultConfig = {
  STRIPE_KEY: process.env.REACT_APP_STRIPE_KEY,
  SALT3: process.env.REACT_APP_SALT3,
  SALT5: process.env.REACT_APP_SALT5,
  GATESTCODE: "UA-71798345-4",
  GALIFECODE: "UA-71798345-1",
  GAEDGECODE: "UA-71798345-2",
  E2EE_IV: process.env.REACT_APP_SALT_ENCRYPT,
  E2EE_KEY: process.env.REACT_APP_SALT_ENCRYPT
};
 
var strippedDownVersion = 0;
if (window.location.hostname == "app.testinabox.com" || window.location.hostname == "shop.testinabox.com") {
  strippedDownVersion = 1;
}

var partnerPlatform = 0; //Needs to be 0 for deployments
if (window.location.hostname == "forthpartners.co.uk" || window.location.hostname == "www.forthpartners.co.uk" || window.location.hostname == "appdev.forthpartners.co.uk" || window.location.hostname == "beta.forthpartners.co.uk" || window.location.hostname == "forthpartners.co.uk") {
  partnerPlatform = 1;
}

var websiteId = 1;
if (window.location.hostname == "localhost" || window.location.hostname == "app.forthwithlife.co.uk" || window.location.hostname == "shop.forthwithlife.co.uk" || window.location.hostname == "appdev.forthwithlife.co.uk" || window.location.hostname == "beta.forthwithlife.co.uk") {
  websiteId = 1;
} else if (window.location.hostname == "app.sportsbloodtests.co.uk" || window.location.hostname == "shop.sportsbloodtests.co.uk" || window.location.hostname == "app.forthedge.co.uk" || window.location.hostname == "shop.forthedge.co.uk" || window.location.hostname == "appdev.forthedge.co.uk" || window.location.hostname == "appdev.sportsbloodtests.co.uk") {
  websiteId = 2;
} else if (window.location.hostname == "app.biomarkertracking.com" || window.location.hostname == "shop.biomarkertracking.com") {
  websiteId = 3;
} else if ((window.location.hostname == "biomarkertracking.com" || window.location.hostname == "www.biomarkertracking.com" || window.location.hostname == "partnershopdev.forthwithlife.co.uk" || window.location.hostname == "betashop.forthpartners.co.uk" || window.location.hostname == "biomarkertracking.com") && window.location.hostname != "app.biomarkertracking.com") {
  websiteId = 4;
} else if (window.location.hostname == "forthpartners.co.uk" || window.location.hostname == "www.forthpartners.co.uk" || window.location.hostname == "appdev.forthpartners.co.uk" || window.location.hostname == "beta.forthpartners.co.uk" || window.location.hostname == "forthpartners.co.uk") {
  websiteId = 5;
} 

var partnerStore = 0;
if ((window.location.hostname == "biomarkertracking.com" || window.location.hostname == "www.biomarkertracking.com" || window.location.hostname == "partnershopdev.forthwithlife.co.uk" || window.location.hostname == "biomarkertracking.com" || window.location.hostname == "betashop.forthpartners.co.uk") && window.location.hostname != "app.biomarkertracking.com") {
  partnerStore = 1;
}

var encryptString = "kczQnOCzFpvRYSHQOFgb";
let postcoderApiKey = "PCWSL-BJ4DS-PSZ7V-Z96S2";

global.config = {
  strippedDownVersion: strippedDownVersion,
  partnerPlatform: partnerPlatform,
  websiteId: websiteId,
  encryptString: encryptString,
  partnerStore: partnerStore,
  postcoderApiKey: postcoderApiKey,
};
 
export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...defaultConfig,
};
