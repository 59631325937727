import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { NavLink, Link, withRouter } from 'react-router-dom'
import { getPartnerNotifications, updatePartnerNotifications } from '../../actions/partners-actions'
import PartnerHeaderNotification from "./PartnerHeaderNotification";

class PartnerHeaderNotifications extends Component {

  constructor(props) {
    super(props); 
    this.state = {
      loading: true, 
      currentPage: 10,
      notifications: {},
      panelIsOpen: false,
      unreadNotificationCount: 0  
    };
  }

  static propTypes = {
    partnerNotifications: PropTypes.object.isRequired,
    updatePartnerNotifications: PropTypes.object.isRequired 
  } 


  componentWillMount(){
 
    this.props.getPartnerNotifications(10).then((response) => {
      if (!response.error) {
        this.handleNotificationReadStatus(response.response.entities.partnerNotifications);
        this.setState({ 
          loading: false, 
          notifications: response.response.entities.partnerNotifications 
        });
      }
    });

  }

 

  getNotifications() {
    let page = this.state.currentPage + 10;
    this.props.getPartnerNotifications(page).then((response) => {
      if (!response.error) { 
        this.handleNotificationReadStatus(response.response.entities.partnerNotifications, true, true);
        this.setState({   
          notifications: response.response.entities.partnerNotifications,
          page: page
        });
      }
    });
  }

  togglePanelOpenState() {
    let panelIsOpen = !this.state.panelIsOpen;
    this.setState({ 
      panelIsOpen: panelIsOpen
    }); 

    if (panelIsOpen) {
      this.handleNotificationReadStatus(this.state.notifications, true);
    } 
  }

  handleNotificationReadStatus(partnerNotifications, updateReadStatus = false, doNotCount = false) {
    let unreadNotificationCount = 0; 
    let readArray = [];


    if (partnerNotifications) {

      Object.keys(partnerNotifications).map(function(key) {  

        if (partnerNotifications[key].userRead == 0) {
          if (updateReadStatus) {
            readArray.push(partnerNotifications[key].iD);
          } else {
            unreadNotificationCount++;
          }
        } 
          
      });

      if (updateReadStatus) {
        let payload = {
          readArray: readArray
        } 
        this.props.updatePartnerNotifications(payload);
        
      }

    }

    if (unreadNotificationCount >= 10) {
      unreadNotificationCount = '10+';
    }
    
    if (doNotCount) {
      unreadNotificationCount = 0;
    }

    this.setState({ 
      unreadNotificationCount: unreadNotificationCount
    });
  }

  handleCloseNotifications = () => {
    const element = document.getElementById("dropdown-drawer")

    if(this.state.panelIsOpen && element.classList.contains("show")){
      document.getElementsByClassName("nav-link dropdown-toggle notification-ui_icon")[0].click()
      this.togglePanelOpenState()
    }
  }
 

  render() { 

    let notifications = this.state.notifications;
 
    return (  
      <div className="text-center w-auto pe-0">
        {this.state.panelIsOpen == true && <div className="notification-backdrop" onClick={this.handleCloseNotifications}></div>}
        <div className="nav-item dropdown notification-ui">
          <a className="nav-link dropdown-toggle notification-ui_icon" href="#" id="navbarDropdown" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="false" onClick={() => this.togglePanelOpenState() }>
            <div className="notifications position-relative">
              <button className="btn dark-padding"><i className="far fa-bell"></i></button>
              {this.state.unreadNotificationCount != 0 && (
                <span className="notif-num"></span>
              )}
            </div>
          </a>
          <div id="dropdown-drawer" className="dropdown-menu notification-ui_dd" aria-labelledby="navbarDropdown">
            <div className="notification-ui_dd-header">
              <div className="row align-items-center">
                <div className="col-9">
                  <h3 className="text-start">Notifications</h3>
                </div>
                <div className="col-3 text-end">
                  <i className="far fa-bell"></i>
                </div>
              </div>
            </div>
{/*             <div className="notification-ui_dd-filter" data-bs-toggle="collapse" data-bs-target="#collapseFilter" aria-expanded="false" aria-controls="collapseFilter">
              <div className="row align-items-center">
                <div className="col-12">
                  <h4 className="text-center m-0">Filter <i className="fas fa-sliders-h ms-2"></i></h4>
                </div> 
              </div>
            </div>

            <div className="collapse filterToggle" id="collapseFilter">
              <div className="card card-body">
                To discuss with Chris/Justin on functionality here.
              </div>
            </div> */}

            {!this.state.loading ? (
              <div className="notification-ui_dd-content">
                {(() => {
                  if (notifications && Object.keys(notifications).length > 0) {
                    return (
                      <>
                      {Object.keys(notifications).map((key)=>(
                        <PartnerHeaderNotification messageAttachment={notifications[key].messageAttachment} messageBody={notifications[key].messageBody} messageSubject={notifications[key].messageSubject} messageUrl={notifications[key].messageUrl} notificationType={notifications[key].notificationType} orderId={notifications[key].orderId} orderReference={notifications[key].orderReference} orderStatus={notifications[key].orderStatus} dateSent={notifications[key].dateSent} userRead={notifications[key].userRead}  />
                      ))}
                      <div onClick={() => this.getNotifications() } className="loadMore">Load More...</div>
                      </>
                    )                  
                  } else {
                    return (
                      <div className="p-3">No notifications to show</div>
                    )
                  }
                })()}
              </div>
            ) : (
              <div>Loading</div>
            )}
             
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {

  const { 
    authentication, 
    user,
    partnerNotifications
  } = state
 

  return {
    authentication, 
    user,
    partnerNotifications 
  }
}

export default withRouter(connect(mapStateToProps, { 
  getPartnerNotifications,
  updatePartnerNotifications
})(PartnerHeaderNotifications))
