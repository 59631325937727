import React, { Component } from 'react'
import { createPortal } from 'react-dom';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { updatesList } from './UpdatesList';
import sortBy from 'lodash/sortBy';
import moment from 'moment';

import {setNewUpdates} from '../../../actions/partners-actions'

const Portal = ({children}) => createPortal(children, document.querySelector("#root"))

class NewUpdates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showUpdates: false,
      hasNewUpdates: this.props.hasNewUpdates
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevState.showUpdates != this.state.showUpdates){
      if(this.state.showUpdates == true) this.disableScroll();
      if(this.state.showUpdates == false) this.enableScroll();
    }

    if(prevProps.hasNewUpdates != this.props.hasNewUpdates){
      this.setState({
        hasNewUpdates: this.props.hasNewUpdates
      })
    }
  }

  disableScroll = () => {
    document.querySelector("#root").style.overflow = 'hidden';
    document.querySelector("#root").style.height = '100vh';
  }

  enableScroll = () => {
    document.querySelector("#root").style.overflow = '';
    document.querySelector("#root").style.height = '';
  }

  handleClick = () => {
    if(this.state.hasNewUpdates == 1){
      this.props.setNewUpdates({hasNewUpdates: false})
    }

    this.setState({ showUpdates: !this.state.showUpdates, hasNewUpdates: false })
  }

  render() {
    const {showUpdates, hasNewUpdates} = this.state
    const updatesListSortedByDate = sortBy(updatesList, (item) => item.date).reverse()

    return (
      <>
        <div className={`text-center w-auto newUpdatesButton ${hasNewUpdates == true ? 'showRedDot' : ""}`} onClick={this.handleClick}>
          <i className="fal fa-gift fa-fw"></i>
        </div>

        {showUpdates && 
          <Portal>
            <div className="newUpdates">
              <div className="newUpdates-backdrop" onClick={() => this.setState({ showUpdates: false })}></div>
              <div className="newUpdates-container">
                <div className="newUpdates-header">
                  <h2 className="mb-0 text-uppercase fw-semibold">What's new</h2>
                  <i className="far fa-times fa-fw fa-2x px-2" onClick={() => this.setState({ showUpdates: false })}></i>
                </div>
                <div className="newUpdates-content">
                  {updatesListSortedByDate?.map( (newUpdate, index) => <div key={index} className="newUpdates-item">
                    <header>{moment(newUpdate.date).format('MMMM YYYY')} [{newUpdate.appVersion}]</header>
                    <main>
                      <h2 className="text-black mt-2 fw-semibold">{newUpdate.title}</h2>
                      {newUpdate.img && <img src={newUpdate.img} alt={newUpdate.title} width="100%" className="mt-2 mb-3"/>}
                      {newUpdate.description && <p dangerouslySetInnerHTML={{__html: newUpdate.description}}></p>}
                      <ul className="p-0 m-0">
                        {newUpdate.features?.map((feature, index) => <li key={index} className="list-unstyled">
                          <h4 className="fw-semibold">{feature.title}</h4>
                          <p dangerouslySetInnerHTML={{__html: feature.description}}></p>
                        </li>)}
                      </ul>
                    </main>
                  </div>)}
                </div>
              </div>
            </div>
          </Portal>
        }
      </>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {};
};
  
export default withRouter(
  connect(mapStateToProps, {
    setNewUpdates
  })(NewUpdates)
);
