import React, { Component } from 'react';
import { Link, withRouter, useHistory } from 'react-router-dom' 
import PropTypes from 'prop-types' 


export default function BackButton(props) {
    let history = useHistory();
    return (
      <button
        className="d-block d-md-none button back-button icon-left"
        onClick={history.goBack()}>
          <i className="fas fa-chevron-left"></i> Back
      </button>
    );
};